import React from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {bindable, customElement, inject, noView} from 'aurelia-framework';
import {DateRangePicker} from "rsuite";
import {transformModelDateToComponentDate} from "./transformer";

import 'rsuite/dist/rsuite.css';
import './date-range-input.less';
import moment from 'moment-timezone';
import DateRangePickerComponent from './DateRangePickerComponent';

Date.prototype.toPlainISO = function () {
    return this.toISOString().substr(0, 19);
};

@noView()
@customElement('sio-date-range-input')
@inject(Element)
export class DateRangeInput {
    @bindable({ defaultBindingMode: 2 }) modelDate;
    @bindable({ defaultBindingMode: 2 }) fromDate;
    @bindable({ defaultBindingMode: 2 }) toDate;

    @bindable options = {};

    constructor(element) {
        this.element = element;
    }

    detached() {
        unmountComponentAtNode(this.element);
    }
    modelDateChanged() {
        this.render();
    }

    fromDateChanged() {
        this.modelDate = [this.fromDate, this.modelDate?.[1] ?? undefined];
    }

    toDateChanged() {
        this.modelDate = [this.modelDate?.[0] ?? undefined, this.toDate];
    }

    optionsChanged() {
        this.render();
    }

    disableDateRange(date) {
        if (!this?.options) {
            return false;
        }

        const { min, max } = this.options;

        date = moment(date).startOf('day');

        return !min && !max
            ? false
            : min && !max
            ? date.isBefore(min)
            : max && !min
            ? date.isAfter(max)
            : min && max
            ? !date.isBetween(min, max, undefined, '[]')
            : false;
    }

    render() {
        const defaultValue = () =>
            this.modelDate?.map((v) =>
                !v
                    ? undefined
                    : new Date(
                          transformModelDateToComponentDate(
                              v,
                              'YYYY-MM-DD',
                              this.options
                          ).date
                      )
            );

        let ranges = [];

        for (let range of this.options?.ranges ?? []) {
            if (typeof range.value[0] === 'string') {
                range.value[0] = moment(range.value[0]).toDate();
            }

            if (typeof range.value[1] === 'string') {
                range.value[1] = moment(range.value[1]).toDate();
            }

            ranges.push(range);
        }

        if (ranges.length === 0) {
            ranges.push({
                label: 'Dieser Monat',
                value: [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                ]
            });

            ranges.push({
                label: 'Dieses Jahr',
                value: [
                    moment().startOf('year').toDate(),
                    moment().endOf('year').toDate(),
                ]
            });

            ranges.push({
                label: 'Nächstes Jahr',
                value: [
                    moment().add(1, 'year').startOf('year').toDate(),
                    moment().add(1, 'year').endOf('year').toDate(),
                ]
            });
        }

        const onDateRangeChange = (value) => {
            this.modelDate = value;
            this.fromDate = value[0] ? value[0] : null;
            this.toDate = value[1] ? value[1] : null;
        };

        render(
            <div className="form-control">
                <DateRangePickerComponent
                    ranges={ranges}
                    disableDateRange={this.disableDateRange.bind(this)}
                    defaultValue={() => defaultValue()}
                    onChange={onDateRangeChange}
                />
            </div>,
            this.element
        );
    }
}

import {bindable, customElement, inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {DialogService} from "aurelia-dialog";

@customElement('sio-uploaded-file-input')
@inject(
    Client,
    DialogService
)
export class UploadedFile {

    defaults = {
        sizeLimit: 10000000000,
        multiple: false,
        allowedExtensions: null,
    };

    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    @bindable uploaded;

    constructor(
        client,
        dialogService
    ) {
        this.client = client;
        this.dialogService = dialogService;
    }

    bind() {
        this._config = Object.assign({}, this.defaults, this.config);
    }

    async onUploadComplete(e) {

        console.debug('UPLOAD COMPLETE', e);

        if (e.detail.realPath) {
            this.value = e.detail.realPath;
            this.filename = e.detail.fileName;
        } else {
            this.value = null;
            this.filename = null;
        }
    }

    removeFile()
    {
        this.value = null;
        this.filename = null;
    }
}

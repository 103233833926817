import {createUseStyles} from "react-jss"
import {accountingStyles} from "../../accounting/styles"

export const getLabelCol = (isSplitView) => ({
    xs: 24,
    sm: isSplitView ? 24 : 9,
    lg: isSplitView ? 24 : 9,
});

export const getPercentageCol = (hideAnzahlCol, isSplitView) => ({
    xs: hideAnzahlCol && isSplitView ? 9 : hideAnzahlCol ? 6 : isSplitView ? 6 : 3,
    sm: hideAnzahlCol && isSplitView ? 7 : hideAnzahlCol ? 3 : isSplitView ? 6 : 2,
    lg: hideAnzahlCol && isSplitView ? 4 : hideAnzahlCol ? 3 : isSplitView ? 6 : 2,
    className: "text-right",
});

export const getPriceCol = (hideAnzahlCol, isSplitView) => ({
    xs: hideAnzahlCol && isSplitView ? 9 : hideAnzahlCol ? 13 : isSplitView ? 6 : 10,
    sm: hideAnzahlCol && isSplitView ? 8 : hideAnzahlCol ? 5 : isSplitView ? 6 : 4,
    lg: hideAnzahlCol && isSplitView ? 6 : hideAnzahlCol ? 5 : isSplitView ? 6 : 4,
    className: "text-right",
});

export const getAnzahlCol = (isSplitView) => ({
    xs: 6,
    sm: isSplitView ? 6 : 4,
    lg: isSplitView ? 6 : 4,
    className: "text-center",
});

export const getActionCol = (isSplitView) => ({
    xs: isSplitView ? 6 : 1,
    sm: isSplitView ? 6 : 1,
    lg: isSplitView ? 6 : 1,
    className: "text-right",
});


export const useStyles = createUseStyles({
    ...accountingStyles,
    panel: {
        padding: "1rem",
        "& .rs-form-horizontal .rs-form-group label.rs-control-label": {
            width: "inherit"
        }
    },
    assignment: {
        marginBottom: ".5rem",
        paddingBottom: ".5rem",
        borderBottom: "1px solid #eee"
    },
    check: {
        color: "green",
        fontWeight: 900
    }
})

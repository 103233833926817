import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {Client} from "../api/client";

@inject(
    DialogController,
    Client
)
export class FlightDialog {

    dialogController;
    title;

    constructor(dialogController, client) {
        this.dialogController = dialogController;
        this.client = client;
    }

    activate(context) {
        this.title = context.title || 'Flug hinzufügen';
    }

    ok(event)
    {
        this.dialogController.ok([event.detail.response.data]);
    }
}

import {inject, bindable, customElement, bindingMode} from "aurelia-framework";
import {Client} from '../api/client';
import {EventAggregator} from "aurelia-event-aggregator";

import "./log.less";
import * as _ from "lodash";
import moment from "moment";

@inject(Client, EventAggregator)
@customElement('sio-log')
export class Log {

    @bindable object;
    @bindable tab;
    @bindable({defaultBindingMode: bindingMode.oneTime}) defaultExpanded = false;

    logs;
    startDate;
    endDate;
    defaultRange;
    loading;
    emptyLoadingIterations;
    user
    range = { startDate: [],endDate: []}
    appendLabel;

    constructor(client, ea) {
        this.client = client;
        this.ea = ea;
        this.logs = [];
        this.user = null;
        this.defaultRange = 1;
        this.startDate = moment().endOf('month').format("YYYY-MM-DD");
        this.endDate = moment(this.startDate).startOf('month').format("YYYY-MM-DD");
        this.loading = false;
        this.emptyLoadingIterations = 0;
        this.appendLabelDate = moment(this.endDate).subtract(this.defaultRange,'months').format("DD.MM.YYYY");

        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {
            if (response.config.modelId != 'log/log') {
                return;
            }

            this.logs = [];
            this.user = [];
            this.defaultRange = 1;
            this.startDate = moment().endOf('month').format("YYYY-MM-DD");
            this.endDate = moment(this.startDate).startOf('month').format("YYYY-MM-DD");
            this.loading = false;
            this.emptyLoadingIterations = 0;

            this.bind();
        });

        this.filterRange=[{
            label: 'Dieser Monat',
            value: [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ]
        }, {
            label: 'Dieses Jahr',
            value: [
                moment().startOf('year').toDate(),
                moment().endOf('year').toDate(),
            ]
        }, {
            label: 'Letzte 12 Monate',
            value: [
                moment().subtract(1, 'year').toDate(),
                moment().toDate(),
            ]
        }, {
            label: 'Gesamt',
            value: [moment('1900-01-01').toDate(), moment().toDate()]
        }]
        this.filterLoading = false;
    }

    bind() {
        this.client.get('log/log-provider', 60).then(provider => {
            this.provider = provider;
        });
        this.getNewLogsFromRange();
        this.showSideFilters = this?.tab?.id === "log"
    }

    detached()
    {
        this.subscription.dispose();
    }

    append()
    {
        // this.startDate = moment(this.startDate).subtract(this.defaultRange,'months').endOf('month').format("YYYY-MM-DD");
        this.endDate = moment(this.endDate).subtract(this.defaultRange,'months').startOf('month').format("YYYY-MM-DD");

        this.getNewLogsFromRange();

        this.appendLabelDate = moment(this.endDate).subtract(this.defaultRange,'months').format("DD.MM.YYYY");
    }

    resetFilter(){
        this.user = null;
        this.startDate = moment().endOf('month').format("YYYY-MM-DD");
        this.endDate = moment(this.startDate).startOf('month').format("YYYY-MM-DD");
        this.emptyLoadingIterations = 0;
        this.logs = [];
        this.append();
    }

    getNewLogsFromRange() {

        this.loading = true;
        this.filterLoading = true;
        let apiUrl = 'log/' + this.object.modelId + '/' + this.object.id + '/' + this.startDate + '/' + this.endDate;

        if (this?.user?.id) {
            apiUrl += '/' + this.user?.id;
        }

        this.client.get(apiUrl).then(logs => {
            this.logs = [];
            _.each(logs, log => {
                this.logs.push(log);
            });

            this.loading = false;
            this.filterLoading = false;
            // load until we have one result, up to 10 times
            if (!this.showSideFilters && logs.length === 0 && this.emptyLoadingIterations < 10) {
                this.emptyLoadingIterations++;

                this.append();
            }
        });
    }
}

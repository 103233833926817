import {Icon, IconButton, Loader, Tooltip, Whisper} from "rsuite";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useItemsQuery} from "../store/costs-api";
import {CurrencyInput} from "../../form/rsuite-form";
import {
    selectAll,
    selectById,
    selectCalculatedPurchasePrice,
    selectNeedsUpdate,
    selectPurchasePrice,
    setCalculatedPurchasePrice,
    setItems,
    setPurchasePrice,
    toggleCalculatedPurchasePriceOverwritten,
    togglePurchasePriceOverwritten,
    updated
} from "../store/report-slice";
import {ReferenceDisplay} from "../../reference/react-reference-display";
import {CostsDialog} from "../costs-dialog";
import AureliaContext from "../../utilities/aurelia-context";
import {useDispatch} from "../../store/store";

/**
 * @type {({purchasePrice: Money, calculatedPurchasePrice: Money}) => JSX.Element}
 */
export default function Assignments(
    {
        id,
        reference,
        value = [],
        purchasePrice,
        calculatedPurchasePrice,
        assignedCosts,
        onChange,
    }
) {
    const {currencyValueConverter, i18n} = useContext(AureliaContext);
    const dispatch = useDispatch();
    const {isLoading, isFetching, data} = useItemsQuery(reference);
    const calculatedPurchasePriceValue = useSelector(selectCalculatedPurchasePrice);
    const purchasePriceValue = useSelector(selectPurchasePrice);
    const items = useSelector(selectAll);
    const needsUpdate = useSelector(selectNeedsUpdate);

    useEffect(
        function () {
            if (data?.items) {
                dispatch(setItems(data.items))
                dispatch(setPurchasePrice(purchasePrice))
                dispatch(setCalculatedPurchasePrice(calculatedPurchasePrice))

                for (const {
                    orderItem: {id},
                    purchasePrice,
                    purchasePriceOverwritten,
                    calculatedPurchasePrice,
                    calculatedPurchasePriceOverwritten
                } of value) {
                    if (purchasePriceOverwritten) {
                        dispatch(setPurchasePrice(purchasePrice ?? {amount: 0, currency: "EUR"}, id));
                    }
                    if (calculatedPurchasePriceOverwritten) {
                        dispatch(setCalculatedPurchasePrice(calculatedPurchasePrice ?? {
                            amount: 0,
                            currency: "EUR"
                        }, id));
                    }
                }
            }
        },
        [reference, isFetching]
    )

    useEffect(
        function () {
            onChange({
                purchasePrice: purchasePriceValue,
                calculatedPurchasePrice: calculatedPurchasePriceValue,
                items: items.map(
                    ({
                         id,
                         purchasePrice,
                         purchasePriceOverwritten,
                         calculatedPurchasePrice,
                         calculatedPurchasePriceOverwritten
                     }) => ({
                        orderItem: {id, modelId: "order/order-item"},
                        purchasePrice,
                        purchasePriceOverwritten,
                        calculatedPurchasePrice,
                        calculatedPurchasePriceOverwritten,
                    }))
            });
            dispatch(updated())
        },
        [needsUpdate]
    )

    return isLoading || isFetching ? <Loader/> : (
        <table className="table table-condensed">
            <thead>
            <tr>
                <th>{i18n.tr("sio.field.orderNumber")}</th>
                <th>{i18n.tr("sio.field.invoiceNumber")}</th>
                <th>{i18n.tr("sio.field.state")}</th>
                <th>{i18n.tr("sio.field.calculatedPurchasePrice")}</th>
                <th>{i18n.tr("sio.field.purchasePrice")}</th>
            </tr>
            <tr>
                <th colSpan={3}></th>
                <th>
                    <CurrencyInput
                        name="calculatedPurchasePrice"
                        value={calculatedPurchasePriceValue}
                        onChange={value => dispatch(setCalculatedPurchasePrice(value))}
                    />
                </th>
                <th>
                    {assignedCosts ? (
                        <div className="text-right">
                            {currencyValueConverter.toView(purchasePrice) + " "}
                            <CostsButton id={id}/>
                        </div>
                    ) : (
                        <CurrencyInput
                            name="purchasePrice"
                            value={purchasePriceValue}
                            onChange={value => dispatch(setPurchasePrice(value))}
                        />
                    )}
                </th>
            </tr>
            </thead>

            <tbody>
            {items.map(
                ({
                     id,
                     orderId,
                     participants,
                     orderNumber,
                     invoiceNumber,
                     bookingStatus,
                 }) => (
                    <React.Fragment key={id}>
                        <tr>
                            <td><a href={"/view/order/detail?id=" + orderId} target="_blank">{orderNumber}</a></td>
                            <td>{invoiceNumber}</td>
                            <td>{i18n.tr("order.bookingStatus." + bookingStatus)}</td>
                            <td rowSpan={2}>
                                <Price id={id} calculated={true}/>
                            </td>
                            <td rowSpan={2}>
                                <Price id={id} calculated={false}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <Participants participants={participants}/>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            )}
            </tbody>
        </table>
    )
}

function Price({id, calculated}) {
    const {currencyValueConverter} = useContext(AureliaContext);
    const field = (calculated ? "calculatedP" : "p") + "urchasePrice";
    const dispatch = useDispatch();
    const {[field]: value, [field + "Overwritten"]: overwritten} = useSelector(state => selectById(state, id));

    if (!overwritten) {
        return (
            <div className="text-right">
                <div style={{float: "left"}}>
                    <Whisper trigger="hover" speaker={<Tooltip>Preis überschreiben</Tooltip>}>
                        <IconButton
                            size="xs"
                            appearance="link"
                            icon={<Icon icon="pencil"/>}
                            onClick={() => dispatch(
                                calculated ? toggleCalculatedPurchasePriceOverwritten(id) : togglePurchasePriceOverwritten(id)
                            )}
                        ></IconButton>
                    </Whisper>
                </div>
                {currencyValueConverter.toView(value)}
            </div>
        );
    }

    return (
        <div>
            <CurrencyInput
                name={field}
                value={value}
                onChange={price => dispatch(
                    calculated ? setCalculatedPurchasePrice(price, id) : setPurchasePrice(price, id)
                )}
                button={
                    <Whisper trigger="hover" speaker={<Tooltip>Preis nicht überschreiben</Tooltip>}>
                        <Icon
                            icon="calculator"
                            size="xs"
                            onClick={() => dispatch(
                                calculated ? toggleCalculatedPurchasePriceOverwritten(id) : togglePurchasePriceOverwritten(id)
                            )}
                        ></Icon>
                    </Whisper>
                }
            />
        </div>
    )
}

const Participants = ({participants = []}) => participants.map((p, i) => (
    <React.Fragment key={i}>
        {0 < i ? "; " : ""}
        <ReferenceDisplay key={i} reference={p}/>
    </React.Fragment>
));

function CostsButton({id}) {
    const {dialogService} = useContext(AureliaContext);
    const [show, setShow] = useState(false);

    useEffect(
        () => {
            if (!show) {
                return;
            }

            (async () => {
                try {
                    await dialogService.open({
                        viewModel: CostsDialog,
                        model: {
                            destination: {id, modelId: "costs/report"},
                            item: {id, modelId: "costs/report"}
                        }
                    }).whenClosed();
                } finally {
                    setShow(false);
                }
            })();
        },
        [show]
    );

    return (
        <Whisper trigger="hover" placement="bottomEnd" speaker={<Tooltip>Belege anzeigen</Tooltip>}>
            <IconButton
                size="xs"
                icon={<Icon icon="info"/>}
                loading={show}
                active={!show}
                onClick={() => setShow(true)}
            ></IconButton>
        </Whisper>
    );
}

import * as React from "react";
import classNames from "classnames";

class SioIcon extends React.Component {
    render() {
        const {
            className,
            classPrefix,
            icon,
            size,
            fixedWidth,
            spin,
            pulse,
            rotate,
            flip,
            stack,
            inverse,
            svgStyle,
            componentClass: Component,
            ...props
        } = this.props;


        const prefix = (cname = "rs-icon") => {
            return (aname) => {

                if (aname && aname.includes('fa-')) {
                    return `${cname} ${aname} sio-icon-padding-fix`;
                }

                return `${cname} ${cname}-${aname}`;
            };
        };
        const addPrefix = prefix(classPrefix ||"rs-icon" );
        const isSvgIcon = typeof icon === "object" && icon.id && icon.viewBox;

        const classes = classNames(className, classPrefix, {
            [addPrefix(typeof icon === "string" ? icon : "")]: !isSvgIcon,
            [addPrefix("fw")]: fixedWidth,
            [addPrefix("spin")]: spin,
            [addPrefix("pulse")]: pulse,
            [addPrefix(`size-${size || ""}`)]: size,
            [addPrefix(`flip-${flip || ""}`)]: flip,
            [addPrefix(`rotate-${rotate || ""}`)]: rotate,
            [addPrefix(`stack-${stack || ""}`)]: stack,
            [addPrefix("inverse")]: inverse,
        });

        if (isSvgIcon) {
            const svgIcon = icon;
            return (
                <i {...props} className={classes}>
                    <svg style={svgStyle} viewBox={svgIcon.viewBox}>
                        <use xlinkHref={`#${svgIcon.id}`} />
                    </svg>
                </i>
            );
        }

        return <i {...props} className={classes} aria-hidden="true"/>;
    }
}

export default SioIcon;

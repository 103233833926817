import StatsLabel from "../../statistics/time-aggregation/stats-label";
import {useContext} from "react";
import AureliaContext from "../../utilities/aurelia-context";
import {Table} from "rsuite";
import Panel from "../../view-block/panel/react-panel";

const {Column, HeaderCell, Cell} = Table;

/**
 * @param {{[category: string]: IncomeSourceCategory}} categories
 * @param {{[key: string]: string|{details: string}}} labels
 * @param {Money} income
 */
export function IncomeSources({categories, labels, income}) {
    const {i18n, currencyValueConverter} = useContext(AureliaContext);
    const data = Object.entries(categories).map(([key, {sum, lockedSum, dirty, incomeSources}]) => ({
        key,
        label: i18n.tr(labels[key]),
        amount: dirty ?
            <strong>{currencyValueConverter.toView(sum)}</strong> :
            currencyValueConverter.toView(sum),
        lockedAmount: !lockedSum?.currency ? "" : currencyValueConverter.toView(lockedSum),
        dirty: dirty && !!lockedSum?.currency,
        children: Object.entries(incomeSources).map(([key, {income, lastLockedIncome, dirty}]) => ({
            key,
            amount: dirty ? <strong>{currencyValueConverter.toView(income)}</strong> :
                currencyValueConverter.toView(income),
            lockedAmount: !lastLockedIncome?.currency ? "" : currencyValueConverter.toView(lastLockedIncome),
            height: labels[key].details ? 56 : 48,
            dirty: !!lastLockedIncome?.currency && dirty,
            label: (
                <>
                    <StatsLabel label={labels[key]}/>
                    {labels[key]?.details && <small><br/>{labels[key]?.details}</small>}
                </>
            ),
        }))
    })).concat([{
        key: "sum",
        amount: <strong>{currencyValueConverter.toView(income)}</strong>,
        lockedAmount: "",
        height: 48,
        label: <strong>Summe:</strong>
    }])

    return (
        <Panel heading="costs.income">
            <Table isTree autoHeight data={data} rowKey="key" rowHeight={row => row?.height ?? 48}>
                <Column flexGrow={1}>
                    <HeaderCell>{i18n.tr("costs.income")}</HeaderCell>
                    <Cell dataKey="label"/>
                </Column>
                <Column align="right">
                    <HeaderCell>VK</HeaderCell>
                    <Cell dataKey="amount"/>
                </Column>
                <Column align="right">
                    <HeaderCell>gebucht</HeaderCell>
                    <Cell dataKey="lockedAmount"/>
                </Column>
                <Column width={24}>
                    <HeaderCell/>
                    <Cell>
                        {({dirty}) => dirty && <span style={{position:"relative", left:"-8px"}}>❗</span>}
                    </Cell>
                </Column>
            </Table>
        </Panel>
    );
}

import {bindable, inject} from "aurelia-framework";
import {Client} from "../../api/client";

@inject(
    Client
)
export class FrontendHomepageConfiguration {
    config = {};

    @bindable website;

    loading = true;
    template = null;

    constructor(client) {
        this.client = client;
    }

    websiteChanged() {
        this.loading = true;
        this.client
            .get('wysiwyg-templates-constructor/wysiwyg-template/' + this.website.frontpageTemplate.id)
            .then(template => {
                this.template = template;
            })
            .finally(() => {
                this.loading = false;
            });
    }
}

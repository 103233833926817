import React, {PureComponent} from 'react';
import {Container, inject} from "aurelia-framework";
import {Client} from "../api/client";
import {Popover, Whisper} from "rsuite";
import SioIcon from '../icon/rsuite-icon-font/SioIcon';
@inject(
    Client
)

export default class NotesReferenceDisplay extends PureComponent {

    client;

    constructor(
        props,
        client
    ) {
        super(props);
        this.client = client;
        this.state = {
            links: []
        };
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reference !== this.props.reference) {
            this.reload();
        }
    }

    reload() {
        let client = Container.instance.get(Client);

        if (!this.props.reference) {
            this.setState({links: []});
        }

        let reference = this.props.reference;

        client.get('global-notes/journey/' + reference.id).then((links) => {

            let newLinks = [];


            if (!Array.isArray(links)) {
                links = [links];
            }

            links.forEach(link => {

                if (link) {
                    newLinks.push(link);
                }
            });


            this.setState({links: newLinks});
        });
    }

    render() {
        console.log(this.state.links);

        return <>
            {this.state.links.map((note) => (
                <p key={note.id}>
                    {note.title}
                    <Whisper trigger="hover"
                             speaker={
                                 <Popover>
                                     <span dangerouslySetInnerHTML={{__html: note.text.de}}/>
                                 </Popover>
                             }>
                        <SioIcon icon="fa fa-circle-info" style={{float: "right"}}/>
                    </Whisper>
                </p>
            ))}
        </>;
    }
}

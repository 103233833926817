import {Container, inject, LogManager} from "aurelia-framework";
import {DialogController, DialogService} from 'aurelia-dialog';
import {Client} from "../api/client";
import * as _ from "lodash";
import $ from "jquery";
import {UserClient} from "../api/user-client";

import "./style.less";

const logger = LogManager.getLogger('UniversalEntitySelect');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

/**
 * @typedef {output: ObjectRef[]} EntitySelectResult
 * @type {
 *     (modelId: string, conditions?: any, context?: {title: string}) => PromiseLike<EntitySelectResult>
 * }
 */
export function selectEntities(selectModelId, conditions, context = {}) {
    /** @type DialogService */
    const dialogController = Container.instance.get(DialogService)
    /** @type DialogSettings */
    const settings = {
        viewModel: UniversalEntitySelect,
        model: {selectModelId, ...context}
    }

    if (conditions) {
        settings.model.conditions = conditions
    }

    return dialogController.open(settings).whenClosed()
}

@inject(DialogController, Client, UserClient)
export class UniversalEntitySelect {

    constructor(
        dialogController,
        client,
        userClient
    ) {
        this.dialogController = dialogController;
        this.client = client;
        this.userClient = userClient;
    }

    async activate(context) {
        this.title = context.title || 'sio.universal_entity_select.title';
        this.selectModelId = context.selectModelId;
        this.currentValueConfig = context.currentValueConfig;
        this.currentValue = context.currentValue && !_.isArray(context.currentValue) ? [context.currentValue] : context.currentValue;
        this.multiSelect = _.has(context, 'multiSelect') ? context.multiSelect : true;
        this.selectedItems = [];
        this.additionalFetchConditions = context.conditions ? context.conditions : await this._getAdditionalFetchConditions(context);
        this.viewContext = context.viewContext;
        this.doubleClickSubmit = this.userClient?.getUser()?.instance?.settings?.instanceGeneral?.doubleClickListSelection || false
    }

    onSelectionChangedCallback() {
        return (selectedItems, submit = false) => {
            this.selectedItems = selectedItems;
            if(submit){
                this.ok()
            }
        }
    }

    async _getAdditionalFetchConditions(context) {
        // TODO: Consider moving this code to core2 API

        let conditions = [];

        if (!context.allowDuplicates) {
            if (context.intermediateModel) {
                // Fetch all items connected with contextObjectRef

                let condition1 = {}, condition2 = {};
                condition1[context.intermediateModel.propertyToSaveContextObjectRef] = {
                    $eq: {id: context.contextObjectRef.id, modelId: context.contextObjectRef.modelId}
                };
                condition2[context.intermediateModel.propertyToSaveSelectedItem] = {
                    $eq: {id: context.contextObjectRef.id, modelId: context.contextObjectRef.modelId}
                };
                let fetchConnectedItemsQuery = {conditions: {$or: [condition1, condition2]}};

                let connectedItems = await this.client.get(
                    context.intermediateModel.modelId + '?' + $.param(fetchConnectedItemsQuery)
                );

                let itemsToIgnore = [];

                _.each(connectedItems.items, (item) => {
                    let itemToIgnoreRef;

                    if (
                        item[context.intermediateModel.propertyToSaveSelectedItem].id === context.contextObjectRef.id &&
                        item[context.intermediateModel.propertyToSaveSelectedItem].modelId === context.contextObjectRef.modelId
                    ) {
                        itemToIgnoreRef = item[context.intermediateModel.propertyToSaveContextObjectRef];
                    } else {
                        itemToIgnoreRef = item[context.intermediateModel.propertyToSaveSelectedItem];
                    }

                    if (itemToIgnoreRef.modelId === context.selectModelId) {
                        itemsToIgnore.push(itemToIgnoreRef.id);
                    }
                });

                // Add query to ignore all connected items

                conditions.push({id: {$nin: itemsToIgnore}});
            } else {
                // TODO: Don't display already added items in list when association is direct
            }
        }

        if (context.distinctSelectedItemAndContextObjectRef) {
            conditions.push({id: {$ne: context.contextObjectRef.id}});
        }

        if (conditions.length > 0) {
            return Promise.resolve({
                '$and': conditions
            });
        }

        return Promise.resolve({});
    }

    detached() {
        logger.debug("Universal entity selector was cancelled");
    }

    ok() {
        return this.dialogController.ok(this.selectedItems);
    }
}

import {customElement, bindable} from 'aurelia-framework';

@customElement('sio-ticket-result')
export class TimeLogCalendar
{
    @bindable searchResult;
    @bindable searchIndex;

    @bindable({defaultBindingMode: 2}) selected;

    formatWaitingTime(arrival, departure){

        let minuteDifference = moment(departure).diff(moment(arrival), 'minutes');
        return [
            String(Math.floor(minuteDifference / 60)).padStart(2, '0'),
            String(minuteDifference % 60).padStart(2, '0')
        ].join(':') + ' h'
    }
}

import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogController} from "aurelia-dialog";
import {FlashService} from "../flash/flash-service";
import {moment} from "moment-timezone";
import * as _ from "lodash";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {ConfigurationLoader} from "../form/loader/configuration-loader";

@inject(Client, DialogController, FlashService, ConfigurationLoader, FormServiceFactory)
export class TicketSearch {

    constructor(client, dialogController, flash, configurationLoader, formServiceFactory) {
        this.client = client;
        this.dialogController = dialogController;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
        this.searchResult = null;
        this.numItineraries = 1;
        this.firstSelectedTicket = null;
        this.secondSelectedTicket = null;

    }

    async activate (context){
        console.log('context', context);

        this.data = context.data;
        this.order = context.order;
        this.participants = context.participants;

        let data = {
            participants: context.participants,
            organization: {
                id: this.order.organization.id,
                modelId: "organization/organization"
            }
        }

        let config = await this.configurationLoader.get('transport/ticket-search', data);

        this.formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data));

        this.selectedItems = [];
    }

    async search() {
        this.loading = true;

        try {
            let value = this.formService.getValue();

            value.organization = {
                id: this.order.organization.id,
                modelId: "organization/organization"
            }

            value.order = {
                id: this.order.id,
                modelId: this.order.modelId
            };

            value.participants = this.participants.map((participant) => {
                return {
                    id: participant.id,
                    modelId: participant.modelId
                };
            });

            let data = (await this.client.post('transport/ticket-search', value)).data;

            this.loading = false;

            // commented out, because of the new logic for DB International
            // this.numItineraries = value.itineraries.length;

            if (data.errors.length > 0){
                _.forOwn(data.errors, (message, key) => {
                    this.flash.error(message);
                })

            } else if (data.result.length > 0) {
                this.searchResult = data;
            } else {
                this.flash.error('Es konnte keine Fahrkarte ermittelt werden.');
            }

        } catch (err){
            this.loading = false;
            this.searchResult = null;

            let error = null;
            if (err && err.data) {
                if (err.data.localizedMessage){
                    error = err.data.localizedMessage;
                } else if (err.data.message) {
                    error = err.data.message;
                }
            }
            console.log(err);
            this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
        }
    }

    back(){
        this.searchResult = null;
    }

    ok(){
        var selectedTickets = [this.firstSelectedTicket, this.secondSelectedTicket].filter(x => !!x);

        if (selectedTickets.length === 0){
            this.flash.error('Bitte ein Ergebnis auswählen');
            return;
        }

        this.data.data = {
            identifier: this.firstSelectedTicket,
            identifier2: this.secondSelectedTicket,
        }

        this.dialogController.ok(this.data);
    }


}

import {inject} from 'aurelia-framework';
import {FormService} from './form-service';
import {BindingSignaler} from 'aurelia-templating-resources';
import {ConditionMatcher} from "../../condition-builder/condition-matcher";

@inject(BindingSignaler, ConditionMatcher)
export class FormServiceFactory
{
    constructor(signaler, conditionMatcher)
    {
        this.signaler = signaler;
        this.conditionMatcher = conditionMatcher;
    }

    getFormService(config, object, contextObjectRef)
    {
        let formService = new FormService(this.signaler, this.conditionMatcher);

        formService.setConfig(config, object, contextObjectRef);

        return formService;
    }

}



export class CollectionToTextValueConverter
{
    toView(value)
    {
        return value ? value.join('\n') : '';
    }

    fromView(value)
    {
        return value.split('\n');
    }
}
import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";

@inject(Client)
@customElement('filter-conditions-calculate-count')
export class FilterConditionInput {

    @bindable object;
    @bindable field;

    calculateCount = [];
    error = false;

    constructor(client) {
        this.client = client;
    }

    bind() {
        this.update();
    }

    fieldChanged() {
        this.update();
    }

    objectChanged() {
        this.update();
    }

    update() {

        this.error = false;
        this.loading = true;

        this.client.get('filter/calculate-count/' + this.object.modelId + '/' + this.object.id + '/' + this.field).then(data => {
            this.calculateCount = data;
            this.loading = false;
        }).catch(error => {
            this.error = true;
            this.loading = false;
        });
    }
}

import {inject} from 'aurelia-framework';
import {Client} from '../../api/client';

@inject(Client)
export class WebsiteContextClient
{
    constructor(client)
    {
        this.client = client;
    }

    async getWebsite(context)
    {
        console.log('WEBSITE', context);

        if (!context || context.modelId !== 'frontend/website' || !context.id) {
            return undefined;
        }

        return this.client.get(`frontend/website/${context.id}`).catch(() => undefined);
    }
}

import {LogManager} from 'aurelia-framework';
import * as _ from 'lodash';
import {FormStructure} from '../object/form-structure';

const logger = LogManager.getLogger('FormService');

export class FormService {
    /** @member FormStructure|FormField */
    config;

    changeCallback;

    static _defaultRootConfig = {
        actionContainerClass: 'pull-right btn-toolbar',
        submitBtnClass: 'btn btn-primary',
        //Conditionally show in create form but not in update
        showGoToDetail: true,
        //Always show button
        alwaysShowGoToDetail: false
    };

    static _defaultLabels = {
        validationError: 'form.validation_error_message',
        communicationError: 'form.communication_error_message',
        success: 'form.success',
        submit: 'form.submit',
        submitAndNew: 'form.submitAndNew',
        submitGoToDetail: 'form.submitAndDetail',
    };

    constructor(signaler, conditionMatcher) {
        this.signaler = signaler;
        this.conditionMatcher = conditionMatcher;
    }

    setConfig(config, value, contextObjectRef, propertyPathPrefix = '') {
        // @fixme contextObjectRef must not be part of FormService. It must be already assigned
        // inside value before form creation.
        // @see src/form/loader/data-loader.js::_addDefaultValuesToData

        this.rootConfig = _.clone(FormService._defaultRootConfig);

        if (config.labels) {
            this.rootConfig.labels = Object.assign({}, FormService._defaultLabels, config.labels);
            delete config.labels;
        } else {
            this.rootConfig.labels = _.clone(FormService._defaultLabels);
        }

        if (config.submitBtnClass) {
            this.rootConfig.submitBtnClass = config.submitBtnClass;
            delete config.submitBtnClass;
        }

        if (config.actionContainerClass) {
            this.rootConfig.actionContainerClass = config.actionContainerClass;
            delete config.actionContainerClass;
        }

        if (config.dynamicFields) {
            this.rootConfig.dynamicFields = config.dynamicFields;
            delete config.dynamicFields;
        }

        if (config.updateModelIds) {
            this.rootConfig.updateModelIds = config.updateModelIds;
            delete config.updateModelIds;
        }

        if (config.showGoToDetail != null) {
            this.rootConfig.showGoToDetail = config.showGoToDetail;
            delete config.showGoToDetail;
        }

        if (config.alwaysShowGoToDetail != null) {
            this.rootConfig.alwaysShowGoToDetail = config.alwaysShowGoToDetail;
            delete config.alwaysShowGoToDetail;
        }

        this.config = new FormStructure(this, config, propertyPathPrefix, value, null, contextObjectRef);
    }

    getConfig() {
        return this.config;
    }

    setValue(value) {
        this.config.setValue(value);
    }

    getValue() {
        return this.config.getValue();
    }

    resetValue() {
        this.config.resetValue();
    }

    resetErrors(silent = false) {
        this.config.resetErrors(silent);
    }

    setErrors(errors, silent = false) {
        if (errors.children) {
            this.config.setErrors(errors, silent);
        }

        logger.debug('Errors', this, errors);
    }

    change(field) {
        if (this.changeCallback) {
            this.changeCallback(field, this);
        }
    }

    getFieldByProperty(property) {
        return _.find(this.config.fields, (field) => field.property === property);
    }

    getFieldByFullProperty(fullProperty) {
        const propertyParts = fullProperty.split('.');

        if (0 == propertyParts.length) {
            return null;
        }

        let field = this.config;

        for (let i = 0, iend = propertyParts.length; i < iend; ++i) {
            field = _.find(field.fields, (field) => field.property === propertyParts[i]);
        }

        return field;
    }
}

import {bindable, customElement, inject, BindingEngine} from "aurelia-framework";
import {Client} from "../api/client";
import {FormField} from "../form/object/form-field";
import {FormStructure} from "../form/object/form-structure";
import * as _ from "lodash";

@inject(Client, BindingEngine)
@customElement('filter-conditions-input')
export class FilterConditionInput {

    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    types = [];
    fields = [];
    selectedFilterType;

    loading = false;

    client;
    binding;
    subscription;

    constructor(client, binding) {
        this.client = client;
        this.binding = binding;
        this.childErrorsChanged = this.childErrorsChanged.bind(this);
    }

    dispose() {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    bind() {
        this.fieldChanged();
        this.valueChanged();
    }

    fieldChanged() {

        let modelId = this.field.options.modelId;

        this.types = [];

        if (!modelId) {
            return;
        }

        this.loading = true;

        this.client.get('filter/choices/' + modelId, 300).then(data => {
            this.types = data;

            this._buildFields();
            this.loading = false;
        });

        if (this.subscription) {
            this.subscription.dispose();
        }

        this.subscription = this.binding.propertyObserver(this.field, 'childErrors').subscribe(this.childErrorsChanged);
    }

    add()
    {
        let config = this.types.find(choice => choice.value === this.selectedFilterType);

        if (!config) {
            this.flash.error('Bitte einen Filter-Typen auswählen');
            return;
        }

        this.value.push({
            fieldName: config.fieldName,
            modelId: config.modelId,
        });
        this.selectedFilterType = null;

        this._buildFields();
    }

    remove(index)
    {
        this.value.splice(index, 1);

        this._buildFields();
    }

    valueChanged() {
        if (!this.value) {
            this.value = [];
        }

        this._buildFields();
    }

    change(field) {

        for (let i = 0; i < this.fields.length; i++) {

            this.value[i] = this.fields[i].getValue();
        }

        this.field.formService.change(this.field);
    }

    childErrorsChanged() {

        console.log('CHILD errors', this.field.childErrors);

        for (let i = 0; i < this.fields.length; i++) {

            let errors = this.field.childErrors[i];

            if (!errors) {
                this.fields[i].resetErrors();
            } else {
                this.fields[i].setErrors(errors);
            }
        }
    }

    _buildFields() {
        if (this.types.length === 0) {
            return;
        }

        this.fields.splice(0, 9999);

        let elements = [];

        for (let i = this.value.length - 1; i >= 0; i--) {

            let value = this.value[i];
            let config = _.cloneDeep(this.types.find(choice => choice.modelId === value.modelId && choice.fieldName === value.fieldName));

            if (!config) {
                this.value.splice(i, 1);
                continue;
            }

            elements.push(new FormStructure(this, {label: config.label, fields: config.fields}, i, value));
        }

        for (let i = elements.length - 1; i >= 0; i--) {
            this.fields.push(elements[i]);
        }
    }
}

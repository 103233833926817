import moment from "moment-timezone";

export class CurrentDateValueConverter
{

    toView(format)
    {
        return moment().format(format);
    }
}

import {InlineViewStrategy, noView} from 'aurelia-framework';

@noView
export class Item
{
    log;

    activate(model) {
        this.log = model.log;
        this.template = model.template;
    }

    getViewStrategy() {
        return new InlineViewStrategy('<template>' + this.template + '</template>');
    }
}

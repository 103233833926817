import {customElement, bindable} from 'aurelia-framework';

import "./email-display.less";

@customElement('sio-log-print-display')
export class EmailDisplay
{
    @bindable log;
}


import React, {PureComponent} from 'react';
import {Container} from "aurelia-framework";
import {ModelLabelService} from "../reference/model-label-service";
import SegmentsHelp from "./segments-help";

export default class FlightReferenceDisplay extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            links: []
        };
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reference !== this.props.reference) {
            this.reload();
        }
    }

    reload() {
        let modelLabelService = Container.instance.get(ModelLabelService);

        if (!this.props.reference) {
            this.setState({links: []});
        }

        let reference = this.props.reference;

        Promise.all([modelLabelService.getReferenceLinks(reference, false)]).then((links) => {

            let newLinks = [];

            links.forEach(link => {

                if (link) {
                    newLinks.push(link);
                }
            });

            this.setState({links: newLinks});
        });
    }

    render() {
        return <>
            {this.state.links.map((link, index) => (
                <p key={index}>{link.reference.shortName} <SegmentsHelp segments={link.reference.segments}/></p>
            ))}
        </>;
    }

}

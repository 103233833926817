import {RouterHistory} from "../router/history";

/**
 * Simple redirect to avoid WordPress's Gutenberg editor redirecting to /post.php on error
 */
export default class ErrorRedirect {
    activate() {
        location.replace(RouterHistory.history[0].href);
    }
}

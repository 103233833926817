import React, {useEffect, useState} from "react"
import {Button, InputGroup, Stack, Tooltip, Whisper} from "rsuite";
import {Check, Close} from "@rsuite/icons";
import Pencil from "@rsuite/icons/legacy/Pencil";
import {createUseStyles} from "react-jss";

const useStyle = createUseStyles({
    inputGroup: {
        "& .rs-picker-toggle": {
            left: 0,
        },
    },
})


export function EditCell(
    {
        value: defaultValue,
        renderer,
        editor: Editor,
        mutation,
        transformSave,
        readonly = false,
        buttonsRight = false,
        stack = {},
        ...props
    }
) {
    const [doSave, {isLoading}] = mutation()
    const [edit, setEdit] = useState(false)
    const [value, setValue] = useState(defaultValue)
    const {inputGroup} = useStyle()

    function save() {
        doSave(transformSave(value))
        setEdit(false)
    }

    function cancel() {
        setValue(defaultValue)
        setEdit(false)
    }

    useEffect(() => cancel(), [JSON.stringify(defaultValue)])

    /* return edit ? (
        <InputGroup size="xs" className={inputGroup}>
            {buttonsRight && <Editor size="xs" value={value} onChange={value => setValue(value)} {...props}/>}

            <InputGroup.Button size="xs" loading={isLoading} onClick={() => save()}>
                <Check/>
            </InputGroup.Button>

            <InputGroup.Button size="xs" loading={isLoading} onClick={() => cancel()}>
                <Close/>
            </InputGroup.Button>

            {!buttonsRight && <Editor size="xs" value={value} onChange={value => setValue(value)} {...props}/>}
        </InputGroup>
    ) :*/
    return (
        <Whisper open={edit} placement="autoVertical" speaker={
            <Tooltip>
                <InputGroup size="xs" className={inputGroup}>
                    {buttonsRight &&
                        <Editor size="xs" value={value} onChange={value => setValue(value)} {...props}/>}

                    <InputGroup.Button size="xs" loading={isLoading} onClick={() => save()}>
                        <Check/>
                    </InputGroup.Button>

                    <InputGroup.Button size="xs" loading={isLoading} onClick={() => cancel()}>
                        <Close/>
                    </InputGroup.Button>

                    {!buttonsRight &&
                        <Editor size="xs" value={value} onChange={value => setValue(value)} {...props}/>}
                </InputGroup>
            </Tooltip>
        }>
            <Stack alignItems="center" {...stack}>
                {buttonsRight && <div>{renderer(defaultValue)}</div>}

                {!readonly && (
                    <Button appearance="link" size="xs" onClick={() => setEdit(true)}>
                        <Pencil/>
                    </Button>
                )}

                {!buttonsRight && <div>{renderer(defaultValue)}</div>}
            </Stack>
        </Whisper>
    )
}

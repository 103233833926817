import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';
import {Client} from "../api/client";
import {FlashService} from "../flash/flash-service";
import {EventAggregator} from 'aurelia-event-aggregator';

@inject(DialogController, Client, FlashService, EventAggregator)
export class CostsDialog {

    dialogController;
    loading;
    items = [];
    ea;

    constructor(dialogController, client, flash, ea) {
        this.dialogController = dialogController;
        this.client = client;
        this.flash = flash;
        this.ea = ea;

        this.reloadItemWorkflow = this.reloadItem.bind(this);
    }

    async activate(context) {
        this.loading = true
        const {destination, calculatedCostsEntries, item} = context
        this.orderItem = item;

        if (destination) {
            const {items, sum} = (await this.client.post("costs/query/real-costs/destination", {destination})).data;

            this.items = items
            this.sum = sum
            this.singleOrigin = false

        } else if (calculatedCostsEntries?.length) {
            await this.updateItem();
        }

        this.loading = false
    }

    async updateItem() {
        this.loading = true;

        //Need to reload item because it might not be up to date after changes
        const orderItem = (await this.client.get("order/order-item/" + this.orderItem.id));

        const calculatedCostsEntries = orderItem?.calculatedCostsEntries ?? [];
        const origins = calculatedCostsEntries.map(({origin}) => origin)

        this.singleOrigin = 1 === new Set(origins.map((obj) => obj?.id)).size
        this.reference = -1 !== calculatedCostsEntries.findIndex(({reference}) => !!reference)
        this.items = calculatedCostsEntries

        if (this.singleOrigin) {
            this.origin = origins[0]
        }

        this.loading = false;
    }

    getReturnContext(item) {
        return {
            formData: item.formData
        };
    }

    reloadItem()
    {
        console.log('UPDATE ITEM');
        this.updateItem().then(() => {});
        this.ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});
    }
}
import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit"
import {realCostsAssignmentDeleted, realCostsAssignmentsAdded} from "./costs-api"

const assignmentAdapter = createEntityAdapter()
const assignmentSlice = createSlice({
    name: "costs/assignment",
    initialState: assignmentAdapter.getInitialState({forceUpdate: false}),
    reducers: {
        setAssignmentPrice: {
            prepare: (id, price) => ({payload: {id, price}}),
            reducer(state, {payload: {id, price}}) {
                assignmentAdapter.updateOne(state, {id, changes: {price}})
            }
        },
        setAssignmentAmount: {
            prepare: (id, amount) => ({payload: {id, amount}}),
            reducer(state, {payload: {id, amount}}) {
                assignmentAdapter.updateOne(state, {id, changes: {amount}})
            }
        },
        setCurrency: {
            reducer(state, {payload}) {
                state.currency = payload
                state.forceUpdate = true
            }
        },
        clear: {
            prepare: (assignments, currencies) => ({payload: {assignments, currencies}}),
            reducer(state, {payload: {assignments, currencies}}) {
                state.currencies = currencies ?? []
                state.forceUpdate = false
                state.currency = assignments[0]?.price?.currency ?? currencies?.[0] ?? "EUR"

                if (assignments?.length) {
                    assignmentAdapter.setAll(
                        state,
                        assignments.map(({id, price, amount}) => ({
                            id,
                            price: !!price ? price : {amount: 0, currency: state.currency},
                            amount: state.entities[id]?.amount ?? amount ?? 1,
                        }))
                    )
                } else {
                    assignmentAdapter.removeAll(state)
                }
            }
        },
    },
    extraReducers: builder => builder
        .addMatcher(realCostsAssignmentDeleted, (state, {meta: {arg: {originalArgs: {id}}}}) => {
            assignmentAdapter.removeOne(state, id)
        })
        .addMatcher(realCostsAssignmentsAdded, state => {
            state.forceUpdate = true
        })
})

const assignment = assignmentSlice.reducer
export default assignment

export const {
    setAssignmentPrice,
    setAssignmentAmount,
    setCurrency,
    clear,
} = assignmentSlice.actions

const selectAssignment = createSelector(state => state.costs, ({assignment}) => assignment)

export const {
    selectAll: selectAllAssignments,
    selectById: selectAssignmentById,
    selectIds: selectAssignmentIds,
    selectTotal: selectAssignmentsTotal
} = assignmentAdapter.getSelectors(selectAssignment)

export const selectForceUpdate = createSelector(selectAssignment, ({forceUpdate}) => forceUpdate)
export const selectCurrencies = createSelector(selectAssignment, ({currencies}) => currencies)
export const selectCurrency = createSelector(selectAssignment, ({currency}) => currency)

export const selectRealCostSaveParams = createSelector(
    selectAllAssignments,
    assignments => ({
        prices: assignments.map(({id, price, amount}) => ({id, amount, price})),
    })
)

export const selectRealCostQueryParams = createSelector(
    [selectRealCostSaveParams, selectCurrency],
    (save, currency) => ({currency, ...save})
)

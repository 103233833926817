import {bindable, customElement, bindingMode} from 'aurelia-framework';

import "./color-palette.less";

const DEFAULT_COLOR = '#000000';

@customElement('sio-color-palette')
export class ColorPalette
{
    @bindable config;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;

    bind()
    {
        const minSlots = this.config.min;
        const maxSlots = this.config.max;

        if (!this.value)
            this.value = [];

        if (this.value.length < minSlots) {
            for (let i = this.value.length; i < maxSlots; ++i)
                this.value.push(DEFAULT_COLOR);
        }

        if (this.value.length > maxSlots) {
            this.value = this.value.slice(0, maxSlots);
        }
    }

    colorPickerChange(event, index)
    {
        this.value[index] = event.target.value;
        this.value = [].concat(this.value); // cloning to satisfy shallow comparison
    }

    addNewColor()
    {
        this.value = [].concat(this.value, [ DEFAULT_COLOR ]);
    }

    removeColor(index)
    {
        this.value = [].concat(this.value.slice(0, index), this.value.slice(index+1));
    }
}

import {bindable, bindingMode, customElement, inject} from "aurelia-framework";
import {Client} from '../api/client';

import "./email-campaign-report.less";

@customElement('sio-email-campaign-report')
@inject(Client)
export class EmailCampaignReport {

    @bindable({defaultBindingMode: bindingMode.oneTime}) emailCampaign;

    constructor(client) {
        this.client = client;
    }

    async emailCampaignChanged() {

        const report = await this.client.get(`email-campaign/campaign/${this.emailCampaign.id}/report`);

        const receiversRatio = 0 === report.receivers ? () => null :
                amount => amount / report.receivers;

        const sentRatio = 0 === report.sent ? () => null :
                amount => amount / report.sent;

        this.tables = [
            [
                [
                    "email-campaign.stats.receivers",
                    report.receivers
                ]
            ],
            [
                [
                    "email-campaign.stats.preparationError",
                    report.preparationErrorSum,
                    receiversRatio(report.preparationErrorSum)
                ],
                [
                    "email-campaign.stats.duplicateEmail",
                    report.preparationError.duplicateEmail,
                    receiversRatio(report.preparationError.duplicateEmail)
                ],
                [
                    "email-campaign.stats.blacklist",
                    report.preparationError.globalBlacklist + report.preparationError.recentHardBounce,
                    receiversRatio(report.preparationError.globalBlacklist + report.preparationError.recentHardBounce)
                ],
                [
                    "email-campaign.stats.recentComplaint",
                    report.preparationError.recentComplaint,
                    receiversRatio(report.preparationError.recentComplaint)
                ],
                [
                    "email-campaign.stats.other",
                    report.preparationError.noCustomer + report.preparationError.emptyEmail + report.preparationError.invalidEmail + report.preparationError.invalidMessage + report.preparationError.unknownPreparationError,
                    receiversRatio(report.preparationError.noCustomer + report.preparationError.emptyEmail + report.preparationError.invalidEmail + report.preparationError.invalidMessage + report.preparationError.unknownPreparationError)
                ],
            ],
            [
                [
                    "email-campaign.stats.prepared",
                    report.prepared,
                    receiversRatio(report.prepared)
                ],
                [
                    "email-campaign.stats.pending",
                    report.pending,
                    receiversRatio(report.pending)
                ],
                [
                    "email-campaign.stats.hardBounce",
                    report.sendingError.hardBounce,
                    receiversRatio(report.sendingError.hardBounce)
                ],
                [
                    "email-campaign.stats.softBounce",
                    report.sendingError.softBounce,
                    receiversRatio(report.sendingError.softBounce)
                ],
                [
                    "email-campaign.stats.sent",
                    report.sent,
                    receiversRatio(report.sent)
                ]
            ],
            [
                [
                    "email-campaign.stats.interactions"
                ],
                [
                    "email-campaign.stats.open",
                    report.tracking.open,
                    sentRatio(report.tracking.open)
                ],
                [
                    "email-campaign.stats.uniqueOpen",
                    report.tracking.uniqueOpen,
                    sentRatio(report.tracking.uniqueOpen)
                ],
                [
                    "email-campaign.stats.link",
                    report.tracking.link,
                    sentRatio(report.tracking.link)
                ],
                [
                    "email-campaign.stats.uniqueLink",
                    report.tracking.uniqueLink,
                    sentRatio(report.tracking.uniqueLink)
                ],
                [
                    "email-campaign.stats.optOut",
                    report.tracking.optOut,
                    sentRatio(report.tracking.optOut)
                ]
            ]
        ];
    }
}

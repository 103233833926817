import {inject} from 'aurelia-framework';
import {I18N} from 'aurelia-i18n';
import * as toastr from "toastr";

import "./flash.less";

/**
 * @type {{
 *     warning: (message: string, title?: string) => Element,
 *     success: (message: string, title?: string) => Element,
 *     error: (message: string, title?: string) => Element
 * }}
 */
@inject(I18N)
export class FlashService {
    hideAutomatically = false;

    constructor(i18n) {
        this.i18n = i18n;

        toastr.options.positionClass = "toast-top-center";
        toastr.options.extendedTimeOut = 0;
        toastr.options.hideDuration = 250;
        toastr.options.preventDuplicates = true;
        toastr.options.preventOpenDuplicates = true;
    }

    flash(type, message, title, parameters, options) {
        const context = parameters ? {sprintf: parameters} : null

        if(type === "success") {
            toastr.remove();
        }

        return toastr[type](
            this.i18n.tr(message, context),
            "string" === typeof title && "" !== title ? this.i18n.tr(title, context) : null,
            {
                ...this.hideAutomatically || type !== 'error' ? {timeOut: 10000} : {
                    closeButton: true,
                    tapToDismiss: false,
                    timeOut: 0,
                    extendedTimeOut: 0
                },
                ...options
            }
        )
    }

    warning(message, title) {
        return this.flash('warning', message, title, null, {timeOut: 10000});
    }

    success(message, title) {
        return this.flash('success', message, title);
    }

    error(message, title, options) {
        return this.flash('error', message, title, null, options);
    }

    /**
     * Displays flash for response
     *
     * If response has localizedMessage uses this, otherwise defaults to defaultMessage
     */
    response({data}, type, defaultMessage = "Ein unbekannter Fehler ist aufgetreten") {
        return this.flash(type, data?.localizedMessage ?? data?.message ?? defaultMessage, null, data?.parameters);
    }
}

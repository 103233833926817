import {inject, LogManager} from "aurelia-framework";
import {FlashService} from "../flash/flash-service.js";

const logger = LogManager.getLogger('form');

@inject(
    FlashService
)
export class SubmitErrorHandler
{
    constructor(flash)
    {
        this.flash = flash;
    }

    handleError(error, formService)
    {
        logger.debug('Received error', error);

        let data = error.data;

        if (data) {
            formService.resetErrors(true);
            formService.setErrors(data.errors || data);
        }

        if (data && data.hasOwnProperty('localizedMessage')) {
            this.flash.error(data.localizedMessage);
        } else if (data && data.hasOwnProperty('error_description')) {
            this.flash.error(data.error_description);
        } else if (403 === error.status) {
            this.flash.error('sio.access_denied');
        } else if (400 !== error.status) {
            this.flash.error(data.message ?? formService.rootConfig.labels.communicationError);
        } else {
            this.flash.error(formService.rootConfig.labels.validationError);
        }
    }
}

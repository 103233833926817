import {inject, LogManager} from "aurelia-framework";
import {I18N} from 'aurelia-i18n';
import {UnsavedChanges, UnsavedChangesPrevent} from "./unsaved-changes-prevent";

const logger = LogManager.getLogger("UnsavedChangesPrevent");

@inject(
    I18N
)
export class UnsavedChangesChecker {

    constructor(i18n)
    {
        this.i18n = i18n;
    }

    canDeactivate(savedState)
    {
        if (UnsavedChanges.ignore || savedState.length === UnsavedChanges.changes.length) {
            return true;
        }

        console.trace("[UnsavedChangesPrevent] Attempted to prevent changes");
        let confirmed = confirm(this.i18n.tr('dialog.ask_really_close'));

        if (!confirmed) {
            return false;
        }

        this.revertChanges(savedState);

        return true;
    }

    revertChanges(savedState)
    {
        // Remove all changes that occurred after saved state
        UnsavedChanges.changes.slice(savedState.length).forEach(key => {
            UnsavedChangesPrevent.unregisterUnsavedChanges(key);
        });
    }
}

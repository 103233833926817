import { bindable, customElement } from 'aurelia-framework';
import $ from 'jquery';

@customElement('email-message-body-embedded')
export class EmailMessageBodyEmbedded {
    @bindable
    body;

    @bindable
    onload;

    resizeIframe()
    {
        if (this.onload) {
            this.onload(this.iframeEl);
        }

        setTimeout(() => {
            // Recalculate iframe height in next rendering frame to avoid scrollbar flicker
            const iframeEl = $(this.iframeEl);
            const height = iframeEl.contents().height();
            iframeEl.height(height + 10);
        });
    }
}

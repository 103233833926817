import {bindable, containerless, customElement, inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {Client} from "../api/client";
import {DialogView} from "../view/dialog-view";

import "./indicator.less";

@containerless()
@inject(Client, DialogService)
@customElement('sio-integrity-chain-indicator')
export class IntegrityChainIndicator {

    @bindable object;

    client;
    dialog;

    constructor(client, dialog) {
        this.client = client;
        this.dialog = dialog;
    }

    loadInfo() {
        console.debug('Loading chain info');

        return this.client
            .get('integrity-chain/entry?embeds[]=chain&conditions=' + JSON.stringify({
                reference: {
                    $eq: {
                        id: this.object.id,
                        modelId: this.object.modelId
                    }
                }
            }))
            .then(response => this.dialog.open({
                viewModel: DialogView,
                model: {
                    title: 'Teil einer Dokumentenrolle',
                    content: '<p>Dieser Datensatz ist Teil von mindestens einer Dokumentenrolle. ' +
                        'Änderungen sind nicht ohne weiteres möglich.</p>' +
                        '<table class="table table-striped table-condensed">' +
                        '<tr><th>Zeitstempel</th><th>Name</th><th>Jahr</th></tr>' +
                        '<tr repeat.for="entry of params.entries">' +
                        '<td textcontent.bind="entry.createdAt | date"></td>' +
                        '<td textcontent.bind="entry.chain.name"></td>' +
                        '<td textcontent.bind="entry.chain.year"></td>' +
                        '</tr>' +
                        '</table>',
                    entries: response.items
                }
            }));
    }
}

import { bindable, customElement, inject, observable } from "aurelia-framework";
import Client from "../api/client";
import { FlashService } from "../flash/flash-service";
import { UserClient } from "../api/user-client";

@inject(Client, FlashService, UserClient)
@customElement("sio-translatable-image")
export class TranslatableImage {
    @bindable field = null;
    @bindable({ defaultBindingMode: 2 }) value = "";
    @bindable currentLanguageItem;

    static locales;


    defaultChoice = {
        id:''
    };
    isMulti = false;
    constructor(client, flash, userClient) {
        this.client = client;
        this.flash = flash;
        this.userClient = userClient;
        this.isLoading = false;
    }

    async bind() {

        this.locales = TranslatableImage.locales ?? this.userClient.getUser()?.instance?.settings?.instanceGeneral?.contentLocales ?? ["de"];
        this.currentLocale = this.locales[0];
        this.isMulti = this.field?.options?.multiple ?? false;

        this.field.multiple = this.isMulti;
        if (this.value?.data !== undefined) {
            this.value = this.value?.data;
        }

        if(!this.value) {
            this.value = {};
        }

        this.defaultChoice.modelId = this.field.modelId;
        this.currentLanguageItem = this.value[this.currentLocale] ?? ( this.isMulti ? [] : {...this.defaultChoice});
        this.field.setValue({...this.value});
    }

    setLocale(locale) {
        this.currentLocale = locale;
        this.currentLanguageItem = this.value[this.currentLocale] ?? ( this.isMulti  ? [] : {...this.defaultChoice});
    }

    updateFieldValue(val){
        if (Array.isArray(val)) {
            this.currentLanguageItem = val ? val.map(item => {
                return {
                    id: item?.id,
                    modelId: item?.modelId,
                }
            }) : [];
            this.value[this.currentLocale] = [...this.currentLanguageItem];
            this.field.setValue({...this.value});
        } else {
            if (this.currentLanguageItem) {
                this.currentLanguageItem.id = val?.id ?? '';
            }
            this.value[this.currentLocale] = {...this.currentLanguageItem};
            this.field.setValue({...this.value});
        }
    }
}

import {inject, LogManager, noView} from 'aurelia-framework';
import {SortableFormLayout} from "./sortable-form-collection";
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import {I18N} from 'aurelia-i18n';
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';

const logger = LogManager.getLogger('SortableDaysCollection');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@noView
@inject(BindingSignaler, EventAggregator, Client, I18N, DialogService)
export class SortableSelectedFieldsCollection extends SortableFormLayout {
    constructor(signaler, ea, client, i18n, DialogService) {
        super(signaler, ea, client, i18n, DialogService);
    }

    _getControlUID() {
        return 'sortable-selected-fields-collection-' + this.config.fullProperty;
    }

    getTabTitle(fieldToFind) {
        const index = this.getFieldIndex(fieldToFind, this.fields);

        let selectedFields = this.config?.options?.sortableSelectedFields ?? []
        let separator = this.config?.options?.sortableSeparator ?? '-';

        let identifier = [];

        selectedFields.forEach(selectedField => {

            let field = fieldToFind.fields.find(field => field.property == selectedField)
            if (field?.value) {
                let transformedField = this.transformField(field);
                if (transformedField) {
                    identifier.push(transformedField);
                }
            }

        });

        let title = identifier.join(separator);


        return index + ' ' + (title || '<Neu>');
    }

    transformField(field) {

        if (['text', 'number'].includes(field.type)) {
            return field.value;
        }

        return null;
    }
}

import {bindable, bindingMode, customElement, inject} from 'aurelia-framework';
import {FlashService} from "../flash/flash-service";

@customElement('sio-context-model-fields')
@inject(FlashService)
export class ContextModelFields {
    @bindable config;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;

    constructor(flashService) {
        this.flashService = flashService;
    }

    allowedAppendFormFieldSubTypes = [
        'textarea'
    ];

    appendFieldNameToAppendField(field) {
        const appendFieldProperty = this.config?.appendFieldProperty ?? null;

        if (true !== (this.config?.isAppendMode ?? false)) {
            return;
        }

        if (null === appendFieldProperty) {
            console.error('Append field property must be set to use the append functionality.');
            this.flashService.error('form.error');

            return;
        }

        const appendField = this.config.formService.getFieldByProperty(appendFieldProperty) ?? null;

        if (null === appendField) {
            console.error('Field with property ' + appendFieldProperty + ' was not found in the form');
            this.flashService.error('form.error');

            return;
        }

        if (!this.allowedAppendFormFieldSubTypes.includes(appendField.subType)) {
            console.error('Field with property ' + appendFieldProperty
                + ' is not supported for variable append functionality.');
            this.flashService.error('form.error');

            return;
        }

        // yes, later would be better to make the "context" path adjustable via field config
        const contextVariable = '{{ context.' + field.fieldName + ' }}';
        appendField.setValue(appendField.value + ' ' + contextVariable);
    }

    toggleAvailableFields() {
        const availableFieldsNode = document.querySelector('.data-model-available-fields');
        const isHidden = getComputedStyle(availableFieldsNode).display === 'none';
        const iconClass = isHidden ? 'fa fa-angle-down' : 'fa fa-angle-up';

        const iconElement = document.querySelector('.toggle-data-model-available-fields__icon');
        iconElement.className = 'toggle-data-model-available-fields__icon ' + iconClass;

        availableFieldsNode.style.display = isHidden ? 'block' : 'none';
    }

    bind() {
        this.config.availableFields = Object.values(this.config.availableFields);
    }
}

import {bindable} from "aurelia-templating";
import {FlashService} from "../../flash/flash-service";

export class DocumentsPreview {
    @bindable documents;

    @bindable onPartialLoad;

    selectedIndex = undefined;
    selectedDocument = undefined;

    loading = false;


    selectDocument = (selectedIndex) => {
        let newDocument = this.documents[selectedIndex];
        this.selectedIndex = selectedIndex;

        if (newDocument.lazy) {

            this.loading = true;

            this.onPartialLoad(newDocument.configurationIndex, newDocument.contextIndex).then((document) => {
                this.loading = false;

                this.selectedDocument = document;
                this.documents.splice(selectedIndex, 1, document);

            }).catch((error) => {
                this.flashService.error(error.message);
            });

        } else {
            this.selectedDocument = newDocument;
        }
    }

    documentsChanged() {
        if ((this.documents || []).length > 0) {
            this.selectDocument(0);
        } else {
            this.selectedIndex = undefined;
            this.selectedDocument = undefined;
        }
    }
}

import {BaseNumberValueConverter} from "./base-number";
import numeral from "numeral";

/**
 * Format numbers values according to format using numeral.
 *
 * Default: percentage with 2 digits
 */
export class FieldColorValueConverter {

    toView(value, column) {
        if (null === value || undefined === value) {
            return '';
        }

        let found = null;

        for (let color of column?.colors ?? []) {

            if (color.type === 'min' && value >= color.limit) {
                found = color.color;
                break;
            } else if (color.type === 'max' && value <= color.limit) {
                found = color.color;
                break;
            }
        }

        return found != null ? 'color: ' + found + ';' : '';
    }
}

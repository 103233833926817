import {inject, LogManager, noView} from 'aurelia-framework';
import Sortable from "sortablejs";
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import {I18N} from 'aurelia-i18n';
import * as _ from 'lodash';
import $ from "jquery";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import  { Confirm } from '../../dialog/confirm';
import {ChoiceLoader} from "../../choice/loader/choice-loader";

const logger = LogManager.getLogger('SortableFormLayout');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@noView
@inject(BindingSignaler, EventAggregator, Client, I18N, DialogService, ChoiceLoader)
export class SortableFormLayout
{
    selectedField = null;
    filters;
    filterPromise = null;

    constructor(signaler, ea, client, i18n, dialog, choiceLoader)
    {
        this.signaler = signaler;
        this.ea = ea;
        this.client = client;
        this.i18n = i18n;
        this.filters = new Map;
        this.dialog = dialog;
        this.choices = {};
        this.choiceLoader = choiceLoader

        this.onFormPostSubmitSubscription = this.ea.subscribe('sio_form_post_submit', response => {
            logger.debug('Update title of tabs after "Save" is hit', response);
            this.signaler.signal('sio__sortable-collection__reload-tab-titles');
        });

        this.onFormValueChangedSubscription = this.ea.subscribe('sio_form_value_changed', response => {
            logger.debug('Update title of tabs after value in form changed', response, this);
            if (response.field && response.field.parent === this.selectedField) {
                this.signaler.signal('sio__sortable-collection__reload-tab-titles');
            }
        });
    }

    activate(field)
    {
        this.config = field;
        this.fields = this.config.fields;

        if (this.fields === null || this.fields.length === 0) {
            return false;
        }

        if (this.fields.length > 0) {
            this.selectedField = this.fields[0];
        }
        const firstField = this.selectedField.fields[0];
        if ('choice' === firstField.type && !this.choices.hasOwnProperty(firstField.set)) {
            this.choiceLoader?.getChoices(firstField).then(choices => {
                this.choices[firstField.set] = choices
            })
        }

    }

    attached()
    {
        let element = document.getElementById(this._getControlUID());

        if (!element) {
            return;
        }

        Sortable.create(element, {
            handle: '.fa-bars',
            forceFallback: true,
            onEnd: this.onEnd.bind(this)
        });
    }

    detached()
    {
        this.onFormPostSubmitSubscription.dispose();
        this.onFormValueChangedSubscription.dispose();
    }

    onEnd(evt)
    {
        logger.debug('on sort', evt, _.clone(this.fields));
        this.config.move(evt.oldIndex, evt.newIndex);

        // Prevent browser scrolling up because of reduction in page height
        $(this.verticalNavRef).css('min-height', $(this.verticalNavRef).height());

        // Force Aurelia to rebuild DOM tree completely
        this.fields = [];
        setTimeout(() => {
            this.fields = this.config.fields;

            // Clean min height value, so if user removes some days, reduction in height will be present
            $(this.verticalNavRef).css('min-height', '');

            this.ea.publish('sio_sortable_form_collection_sort_changed', { config: this.config });
        });
    }

    select(field)
    {
        this.selectedField = field;
    }

    add()
    {
        this.config.add();

        $(this.verticalNavRef).animate({ scrollTop: $(this.verticalNavRef)[0].scrollHeight}, 100);
        this.selectedField = this.fields[this.fields.length - 1];
    }

    duplicate(index)
    {
        let value = this.config.getValue();

        this.config.add(value[index], false, index + 1);

        if (null == this.selectedField) {
            this.selectedField = this.fields[this.fields.length - 1];
        }
    }

    remove(field)
    {
        this.dialog.open(
            {
                viewModel: Confirm,
                model: {
                    message: "Wollen Sie diesen Eintrag wirklich löschen?",
                    title: field +1 || 1 ,
                    okMessage: 'Verwerfen',
                    cancelMessage: 'sio.cancel'
                }
            }
        ).whenClosed(({wasCancelled}) => {
            if(!wasCancelled){
                if (field.property) {
                    field = this.getFieldIndex(field, this.fields)
                }
                this.selectedField = null;
                this.config.remove(field);
                this.signaler.signal('sio__sortable-collection__reload-tab-titles');
                this.selectedField = this.fields[0];
            }
        });
    }

    hasErrors(field)
    {
        for (let i = 0; i < field.fields.length; i++) {

            if (!field.fields[i].valid) {
                return true;
            }
        }

        return false;
    }

    _getControlUID()
    {
        return 'sortable-collection-' + this.config.fullProperty;
    }

    getTabTitle(fieldToFind)
    {
        const index = this.getFieldIndex(fieldToFind, this.fields) + 1;
        const firstField = fieldToFind.fields[0];
        let title = firstField.value;

        if (title && typeof title === 'object') {
            title = title[Object.keys(title)[0]];
        }

        // ---- Filter selection special case
        // @fixme make generic
        if (fieldToFind.parent && 'filters' === fieldToFind.parent.fullProperty) {
            const value = fieldToFind.getValue();

            if (!value || !value.id) {
                return index + ' <Neu>';
            }

            if (!this.filters.has(value.modelId)) {
                if (!this.filterPromise) {
                    this.filterPromise = this.client.get('dynamic-query/filters?modelId=' + value.modelId).then(filters => {
                        this.filters.set(value.modelId, filters);
                        this.filterPromise = null;
                        this.signaler.signal('sio__sortable-collection__reload-tab-titles');
                    });
                }

                return index + ' <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>';
            } else {
                return index + ' ' + this.i18n.tr(this.filters.get(value.modelId).find(filter => filter.id === value.id).name);
            }
        }
        // ------

        if ('choice' === firstField.type) {
            let findLabel = false;
            for (let choice of firstField.choices) {
                if (choice.value === title) {
                    title = choice.label;
                    findLabel =  true;
                    break;
                }
            }
            if (!findLabel && this.choices[firstField.set]) {
                for (let choice of this.choices[firstField.set]) {
                    if (choice.value === title) {
                        title = choice.label
                        findLabel =  true;
                        break;
                    }
                }
            }
        }

        return index + ' ' + (title || '<Neu>');
    }

    getFieldIndex(fieldToFind, fields)
    {
        return fields.findIndex(field => field === fieldToFind);
    }
}

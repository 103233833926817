import React from "react";
import moment from "moment";

function getMonths() {
    return [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
    ];
}

function _getDate(date) {
    //We use regular moment here, as we do not want timezones here
    date = moment.utc(date);

    let month = date.format('M');

    return date.format('DD') + getMonths()[month - 1];
}

function _getTime(date) {
    //We use regular moment here, as we do not want timezones here
    date = moment.utc(date);

    return date.format('HH:mm');
}

function _hasDiff(date1, date2) {
    date1 = moment.utc(date1);
    date2 = moment.utc(date2);

    return date1.format('DDMMYYYY') !== date2.format('DDMMYYYY');
}

function _formatTime(value) {
    if (!value) {
        return null;
    }

    let negative = false;
    if (value < 0) {
        negative = true;
        value = Math.abs(value);
    }

    let hours = Math.floor(value / 3600);
    let minutes = Math.floor((value - (hours * 3600)) / 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return (negative ? '-' : '') + hours + ':' + minutes;
}

function _getOverNight(segment){
    if (_hasDiff(segment.departureTime, segment.arrivalTime)){
        return ' +1';
    } else {
        return '';
    }
}

export const SegmentsTable = ({segments}) => (
    segments.map((segment) => {
        return <tr key={segment.id}>
            <td style={{width: '13%'}}>
                <span>{_getDate(segment.departureTime)}</span>
            </td>
            <td style={{width: '17%'}}>
                <span>{segment.departureIata}</span> –
                <span>{segment.destinationIata}</span>
            </td>
            <td style={{width: '17%'}}>
                <span>{segment.airlineIata}</span> {segment.productIdentifier}
                {segment.operatingAirlineIata &&
                    <span>
                        (by {segment.operatingAirlineIata})
                    </span>
                }
            </td>
            <td style={{width: '23%'}}>
                {_getTime(segment.departureTime)} –
                {_getTime(segment.arrivalTime)}
                {_getOverNight(segment)}
            </td>
            <td style={{width: '15%'}}>
                {_formatTime(segment.duration)} h
            </td>
            <td style={{width: '15%'}}>
            </td>
        </tr>
    })
)

export const SegmentsHelp = ({segments}) => (
    <table className="table">
        <tbody>
        <SegmentsTable segments={segments ?? []}/>
        </tbody>
    </table>
);

export default SegmentsHelp;

import { noView, inject, LogManager } from "aurelia-framework";
import { SortableFormLayout } from "./sortable-form-collection";
import { BindingSignaler } from "aurelia-templating-resources";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import { Client } from "../../api/client";
import { DialogService } from "aurelia-dialog";

const logger = LogManager.getLogger("SortableDaysCollection");
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@noView
@inject(BindingSignaler, EventAggregator, Client, I18N, DialogService)
export class SortableDaysCollection extends SortableFormLayout {
    constructor(signaler, ea, client, i18n, DialogService) {
        super(signaler, ea, client, i18n, DialogService);
    }

    _getControlUID() {
        return "sortable-days-collection-" + this.config.fullProperty;
    }

    getTabTitle(fieldToFind) {
        const index = this.getFieldIndex(fieldToFind, this.fields);
        let title = fieldToFind.getFieldByProperty("title")?.value;

        if (
            title &&
            typeof title === "object" &&
            Object.keys(title).length > 0
        ) {
            title = title[Object.keys(title)[0]];
        }

        const prefix = this._getTabPrefix(index);

        return `<span class="days-count"> ${prefix} | </span>${title ? title : "<Neu>"}`;
    }

    _getTabPrefix(index) {
        let lastRealWorldDay = 1;
        let days = this.config.formService.getValue().days;

        days.forEach((day) => {
            if (day.realWorldDuration == null) {
                day.realWorldDuration = 1;
            }

            if (Number(day.realWorldDuration) === 1) {
                day.realWorldInterval = lastRealWorldDay;
            } else {
                const startingDay = Number(lastRealWorldDay);
                const endingDay = Number(startingDay) + Number(day.realWorldDuration) - 1;

                day.realWorldInterval = startingDay + "-" + endingDay;
            }

            lastRealWorldDay += Number(day.realWorldDuration);
        });

        logger.debug("Days", days);

        if (null == days[index]) {
            return "";
        }

        return days[index].realWorldInterval;
    }
}

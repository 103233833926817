import moment from "moment-timezone";

export class ModifyDateValueConverter
{

    toView(date, modify, type = 'days')
    {
        return moment(date).add(modify, type).format('YYYY-MM-DDTHH:mm:ssZ');
    }
}

import {noView, inject, LogManager} from 'aurelia-framework';
import Sortable from "sortablejs";
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import * as _ from 'lodash';

const logger = LogManager.getLogger('SortableSingleField');

@noView
@inject(BindingSignaler, EventAggregator)
export class SortableSingleField
{
    constructor(signaler, ea)
    {
        this.signaler = signaler;
        this.ea = ea;
    }

    activate(field)
    {
        this.config = field;
        this.fields = this.config.fields;
    }

    attached()
    {
        let element = document.getElementById(this._getControlUID());

        if (!element) {
            return;
        }

        Sortable.create(element, {
            handle: '.fa-bars',
            forceFallback: true,
            onEnd: this.onEnd.bind(this)
        });
    }

    onEnd(evt)
    {
        logger.debug('on sort', evt, _.clone(this.fields));
        this.config.move(evt.oldIndex, evt.newIndex);

        this.ea.publish('sio_sortable_form_collection_sort_changed', { config: this.config });
    }

    add()
    {
        this.config.add();
    }

    remove(field)
    {
        let index = this.getFieldIndex(field, this.fields);
        this.config.remove(index);
    }

    _getControlUID()
    {
        return 'sortable-single-field-' + this.config.fullProperty;
    }

    getFieldIndex(fieldToFind, fields)
    {
        return fields.findIndex(field => field == fieldToFind);
    }
}

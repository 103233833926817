import {useContext} from "react"
import {useSelector} from "react-redux"
import {Button} from "rsuite"
import {useRealCostsSaveMutation} from "../store/costs-api"
import {selectRealCostSaveParams} from "../store/assignment-slice"
import {useStyles} from "./constants"
import AureliaContext from "../../utilities/aurelia-context";

export default function SaveButton({ origin, note, style }) {
    const {i18n} = useContext(AureliaContext)
    const params = useSelector(selectRealCostSaveParams)
    const [doSave, {isLoading}] = useRealCostsSaveMutation()
    const {danger} = useStyles()

    return (
        <Button
            loading={isLoading}
            className={danger}
            style={style}
            onClick={() => doSave({ origin, note, ...params })}
        >
            {i18n.tr("sio.save")}
        </Button>
    );
}

import {bindable, customElement, inject} from 'aurelia-framework';
import * as uploader from "fine-uploader";
import {AuthTokenStorage} from '../auth/auth-token-storage';
import {AureliaConfiguration} from 'aurelia-configuration';

import "./uploader.less";

@customElement('sio-uploader')
@inject(AuthTokenStorage, AureliaConfiguration)
export class Uploader {

    @bindable config;

    @bindable keepLocal = false;

    uploader;
    uploaderElement;

    uploads = [];
    fileProgresses = {};

    constructor(authTokenStorage, appConfig) {
        this.authTokenStorage = authTokenStorage;
        this.appConfig = appConfig;
    }

    onUploadComplete(id, name, object) {
        this.uploaderElement.dispatchEvent(new CustomEvent('sio-upload-complete', {bubbles: true, detail: object}));

        this.updateUploads();
    }

    updateUploads() {
        let uploads = this.uploader.getUploads();
        let notFinishedUploads = [];

        console.debug('UPLOADS', uploads);

        for (let upload of uploads) {
            if (upload.status != "upload successful" && upload.status != "canceled") {
                notFinishedUploads.push(upload);
            }
        }

        this.uploads = notFinishedUploads;

        //this.ref.detectChanges();
    }

    bind() {
        this._config = this.config;

        console.debug('UPLOADER config', this._config);

        this.bootstrapUploader();
    }

    bootstrapUploader() {

        new uploader.DragAndDrop(
            {
                dropZoneElements: [this.dropZone],
                callbacks: {
                    processingDroppedFilesComplete: (files, dropTarget) => {
                        this.uploader.addFiles(files);

                        this.updateUploads();
                    }
                }
            }
        );

        let token = this.authTokenStorage.getAccessToken();

        let params = {
            'public': this._config.public,
            'keepLocal': this.keepLocal
        };

        if (this._config.storage) {
            params.storage = this._config.storage;
        }

        this.uploader = new uploader.FineUploaderBasic(
            {
                debug: true,
                request: {
                    endpoint: this.appConfig.get('apiUrl') + 'file/file/_uploader/upload/upload',
                    customHeaders: {
                        //Todo refetch token
                        "Authorization": "Bearer " + token,
                    },
                    params: params
                },
                chunking: {
                    enabled: true,
                    partSize: 1000000
                },
                multiple: this._config.multiple,
                validation: {
                    sizeLimit: this._config.sizeLimit,
                },
                extraButtons: [
                    {
                        element: this.fileUploadButton
                    }
                ],
                callbacks: {
                    onComplete: this.onUploadComplete.bind(this),
                    onProgress: (id, name, uploadedBytes, totalBytes) => {
                        let progress = Math.round((uploadedBytes / totalBytes) * 100) + '%';

                        if (this.fileProgresses[id] != null && this.fileProgresses[id].progress === progress) {
                            return;
                        }

                        console.debug('ON PROGRESS', progress);

                        this.fileProgresses[id] = {
                            id: id,
                            uploadedBytes: uploadedBytes,
                            totalBytes: totalBytes,
                            progress: progress
                        };

                        //Todo update?

                    },
                    onStatusChange: () => {
                        this.updateUploads();
                    }
                }
            }
        );
    }

}

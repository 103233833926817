import {observable, inject, LogManager} from "aurelia-framework";

const logger = LogManager.getLogger('form-input');

export class FormElement {

    /** @member FormField */
    field;

    activate(context) {
        this.field = context;
    }
}

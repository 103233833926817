import {customElement, bindable, bindingMode} from 'aurelia-framework';

import "./email-display.less";

@customElement('sio-log-email-display')
export class EmailDisplay
{
    @bindable({defaultBindingMode: bindingMode.oneTime}) display = false;

    @bindable log;

    expand()
    {
        this.display = !this.display;
    }
}


import * as _ from 'lodash';

/**
 * BindFormValuesService sets form fields default values from calculated values
 * that were specified in _bindings_ and _bindingContext_.
 *
 * Bindings contain rules that must be used to calculate values for form fields.
 * Example of well-formed bindings see in `customer/customer` model .yml configuration,
 *                                        `bindValues` property in `actions` field.
 *
 * Binding context contains values that should be substituted in rules in bindings.
 * Example of well-formed bindingContext @see /src/form/loader/data-loader.js
 */
export class BindFormValuesService
{
    /**
     * Set form field values by using bindings as rules and specified bindingContext as source of values.
     *
     * @param {object} data Form fields values
     * @param {object} bindings Rules to apply
     * @param {object} bindingContext Binding context with values to use
     *
     * @returns {object} Values of form fields
     */
    /*public*/ static bindValues(data, bindings, bindingContext)
    {
        if (!bindings) {
            return data;
        }

        // See sio-module/customer/customer.yml for example of correct bindValues
        bindings.forEach(binding => {
            _.set(data, binding.property, this._calculateBindingValue(binding.value, bindingContext));
        });

        return data;
    }

    /*private*/ static _calculateBindingValue(fieldValue, bindingContext)
    {
        fieldValue = _.clone(fieldValue); // We don't want to overwrite value that was provided as parameter

        if (_.isArray(fieldValue)) {

            _.each(fieldValue, (itemValue, itemKey) => {
                fieldValue[itemKey] = this._calculateBindingValue(itemValue, bindingContext);
            });

        } else if (_.isObject(fieldValue)) {

            _.each(fieldValue, (propertyValue, propertyKey) => {
                fieldValue[propertyKey] = this._processPattern(propertyValue, bindingContext);
            });

        } else {

            fieldValue = this._processPattern(fieldValue, bindingContext);

        }

        return fieldValue;
    }

    /*private*/ static _processPattern(value, bindingContext)
    {
        if (!_.isString(value) || value.length === 0 || value[0] !== '@') {
            return value; // Specified value is not for binding via context
        }

        let contextObjectName = '';
        let contextObjectNameEnd = value.indexOf('.');
        if (contextObjectNameEnd === -1) {
            contextObjectName = value;
        } else {
            contextObjectName = value.substr(0, contextObjectNameEnd);
        }

        let contextObject = bindingContext[contextObjectName];
        if (contextObject) {
            if (contextObjectNameEnd === -1) {
                value = contextObject;
            } else {
                let propertyPath = value.substr(contextObjectNameEnd + 1);
                value = _.get(contextObject, propertyPath);
            }
        }

        return value;
    }
}

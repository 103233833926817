import {LogManager} from "aurelia-framework";
import * as _ from "lodash";

export class ConditionMatcher {
    logicalOperators = [
        '$and',
        '$or'
    ];

    constructor() {
        this.logger = LogManager.getLogger('table');
    }

    /**
     * Matches conditions
     *
     * @param object
     * @param conditions
     * @returns boolean
     */
    matchConditions(object, conditions) {

        let result = true;

        _.forIn(conditions, (value, fieldOrOperator) => {

            let isLogicalOperator = this.logicalOperators.indexOf(fieldOrOperator) > -1;

            if (isLogicalOperator) {

                result = this.matchLogicalOperator(fieldOrOperator, object, value);

            } else {
                result = this.matchField(fieldOrOperator, object, value);
            }

            if (result === false) {
                return false;
            }
        });

        return result;

    }

    /**
     * Matches logical operator
     *
     * @param logicalOperator
     * @param object
     * @param conditions
     * @returns boolean
     */
    matchLogicalOperator(logicalOperator, object, conditions) {
        let isAnd = (logicalOperator === '$and');
        let result = isAnd;

        _.each(conditions, (condition) => {

            let subResult = this.matchConditions(object, condition);

            if (!subResult && isAnd) {
                result = false;
                return false;
            } else if (subResult && !isAnd) {
                result = true;
                return false;
            }
        });

        return result;
    }

    /**
     * If the given field on the object matches the set of conditions.
     *
     * @param field
     * @param object
     * @param conditions
     * @returns boolean
     */
    matchField(field, object, conditions) {
        // noinspection JSCheckFunctionSignatures
        let value = _.get(object, field);

        if (!_.isObject(conditions)) {
            conditions = {
                $eq: conditions
            };
        }

        let result = true;

        _.forIn(conditions, (condition, operator) => {

            switch (operator) {
                case '$eq':
                    result = value === condition;
                    break;
                case '$gt':
                    result = value > condition;
                    break;
                case '$gte':
                    result = value >= condition;
                    break;
                case '$lt':
                    result = value < condition;
                    break;
                case '$lte':
                    result = value <= condition;
                    break;
                case '$ne':
                    // noinspection EqualityComparisonWithCoercionJS
                    result = value != condition;
                    break;
                case '$match':
                    result = _.includes(value, condition);
                    break;
                case '$contains':
                    result = _.includes(value, condition);
                    break;
                case '$in':
                    result = (condition.indexOf(value) > -1);
                    break;
                case '$nin':
                    result = (condition.indexOf(value) == -1);
                    break;
                case '$exists':
                    result = condition === true ? value != null : value == null;
                    break;
                case '$empty':
                    if (_.isArray(value) || _.isObject(value)) {
                        // noinspection EqualityComparisonWithCoercionJS
                        result = condition == _.isEmpty(value);
                    } else {
                        result = condition == (value == 0 || value == '' || value == null);
                    }
                    break;
                default:
                    this.logger.info(
                        'Unsupported operator given for condition matcher: ' + operator +
                        '. Assuming condition matches, so api can decide.'
                    );
                    result = true;
            }

            if (false === result) {
                return false;
            }
        });

        return result;
    }
}

import { HttpClient } from 'aurelia-fetch-client';
import { bindable, customElement, inject, NewInstance } from 'aurelia-framework';
import { LogRequestInterceptor } from '../api/log-request-interceptor';
import { SentryRequestInterceptor } from '../api/sentry-request-interceptor';
import { AuthInterceptor } from '../auth/auth-interceptor';

import "./email-message-body.less";

@customElement('email-message-body')
@inject(NewInstance.of(HttpClient), AuthInterceptor, LogRequestInterceptor, SentryRequestInterceptor)
export class EmailMessageBody {
    @bindable
    bodyUrl;

    loaded = false;

    constructor(client, authInterceptor, logInterceptor, sentryInterceptor)
    {
        this.client = client;
        this.client.configure(config => {
            config
                .useStandardConfiguration()
                .withInterceptor(authInterceptor)
                .withInterceptor(sentryInterceptor)
                .withInterceptor(logInterceptor);
        });
    }

    async bodyUrlChanged()
    {
        if (!this.bodyUrl) {
            this.body = '';
            this.loaded = true;
            return;
        }

        this.loaded = false;

        await this.client.fetch(this.bodyUrl, { method: 'GET' })
            .then(response => response.text())
            .then(body => this.body = body)
        ;
    }

    onload(iframeEl)
    {
        this.loaded = true;
    }
}

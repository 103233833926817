import {bindable, bindingMode, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import {Provider} from "react-redux";
import store from "../store/store";
import React from "react";
import Assignments from "./report/assignments";

@noView
@inject(Element)
@customElement("cost-report-assignments")
export class CostReportAssignments {
    @bindable({defaultBindingMode: bindingMode.oneTime}) field;
    @bindable({defaultBindingMode: bindingMode.twoWay}) value;

    constructor(container, cvc, i18n, dialog, nvc) {
        this.container = container;
    }

    bind() {
        const {reference, id} = this.field.formService.getValue() ?? {};
        this.reference = reference;
        this.id = id;
        this.initialChange = true;

        for (const field of this.field.formService.config.fields) {
            switch (field.property) {
                case "calculatedPurchasePrice":
                    this.calculatedPurchasePrice = field;
                    break;

                case "purchasePrice":
                    this.purchasePrice = field;
                    break;

                case "assignedCosts":
                    this.assignedCosts = field;
                    break;
            }
        }
    }

    valueChanged() {
        this.attached()
    }

    onChange({items, purchasePrice, calculatedPurchasePrice}) {
        console.debug("value changed", {items, purchasePrice, calculatedPurchasePrice, init: this.initialChange})

        // avoid overwriting not yet set value of Aurelia form
        if (!this.initialChange) {
            this.purchasePrice.setValue(purchasePrice);
            this.calculatedPurchasePrice.setValue(calculatedPurchasePrice);
        }

        this.value = items;
        this.initialChange = false;
    }

    attached() {
        render(
            <Provider store={store}>
                <Assignments
                    id={this.id}
                    reference={this.reference}
                    value={this.value ?? []}
                    purchasePrice={this.purchasePrice.getValue() ?? {amount: 0, currency: "EUR"}}
                    calculatedPurchasePrice={this.calculatedPurchasePrice.getValue() ?? {amount: 0, currency: "EUR"}}
                    assignedCosts={!!this.assignedCosts.getValue()}
                    onChange={this.onChange.bind(this)}
                />
            </Provider>,
            this.container
        );
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}

import "./editor.less";

import {bindable, customElement, inject} from 'aurelia-framework';
import {DialogService} from "aurelia-dialog";
import EditorModal from "./editor-modal";
import EditorContent from "./utils/editor-content";
import {Client} from "../api/client";
import {WebsiteContextClient} from "./utils/website-context-client";
import queryString from "../utilities/query-string";

@inject(DialogService, Client, WebsiteContextClient)
@customElement('sio-editor')
export class Editor {

    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    modal;
    dialog;

    constructor(dialog, client, websiteContextClient) {
        this._id = EditorContent.id++;
        this.dialog = dialog;
        this.client = client;
        this.websiteContextClient = websiteContextClient;
    }

    _getControlUID() {
        return 'editor___' + this._id;
    }

    bind() {
        this.modal = false !== this.config.options.modal;
    }

    attached() {
        if (this.modal) {
            return;
        }

        const query = queryString({
            reference: {
                id: this.reference().id,
                modelId: this.reference().modelId
            }
        });

        this.client.get('editor/dynamic-block-configuration?' + query).then(dynamicBlockConfig => {

            import(/* webpackMode: "lazy", webpackChunkName: "editor" */ "./utils/editor-loader" )
                .then(async ({initializeEditor, shutdownEditor}) => {
                    initializeEditor(
                        this._id,
                        this.config.options.blocks,
                        this.data,
                        dynamicBlockConfig
                    );

                    this.shutdownEditor = shutdownEditor;
                });
        });
    }

    detached() {
        if (this.modal) {
            return;
        }

        this.shutdownEditor(this._id);
    }

    open() {
        return this.dialog.open({
            viewModel: EditorModal,
            model: {
                label: this.config.label,
                content: this.data,
                context: this.config.options.context,
                blocks: this.config.options.blocks,
                editor: this
            }
        })
    }

    get data() {
        return this.value || '';
    }

    set data(content) {
        console.debug('Save data', content);
        this.value = content;
    }

    get reference() {
        return this.config?.formService?.reference;
    }
}

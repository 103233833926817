import {AverageRating} from "./average-rating";

export class CustomerMoodValueConverter extends AverageRating {
    //noinspection JSUnusedGlobalSymbols
    toView(value) {
        let rating = super._getAverageRating(value);

        if ('number' === typeof rating) {
            if (rating < 7) {
                return '😞';
            } else if (rating > 8) {
                return '😃';
            } else {
                return '😑';
            }
        }

        return '❓';
    }
}

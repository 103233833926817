import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Col, Form, InputNumber, RadioGroup, Row } from "rsuite";
import StatsLabel from "../../statistics/time-aggregation/stats-label";
import { selectAssignmentById, setAssignmentAmount, setAssignmentPrice } from "../store/assignment-slice";
import {
    getLabelCol,
    getAnzahlCol,
    getActionCol,
    getPercentageCol,
    getPriceCol,
    useStyles,
} from "./constants";
import DeleteButton from "./delete-button";
import AureliaContext from "../../utilities/aurelia-context";
import { CurrencyInput } from "../../form/rsuite-form";

export default function Assignment({ id, origin, destination, percentage, calculatedSum, additionalInfo, hideAnzahlCol, isSplitView }) {
    const { currencyValueConverter, percentageValueConverter } = useContext(AureliaContext);
    const dispatch = useDispatch();
    const { assignment } = useStyles();
    const { price, amount } = useSelector((state) => selectAssignmentById(state, id));

    function amountChange(amount) {
        dispatch(setAssignmentAmount(id, parseInt(amount)));
    }

    return (
        <Row className={assignment}>
            <Col {...getLabelCol(isSplitView)}>
                <StatsLabel label={destination} />
                {!!additionalInfo?.length && (
                    <div>
                        <small>{additionalInfo.join("; ")}</small>
                    </div>
                )}
            </Col>

            {!hideAnzahlCol && (
                <Col  {...getAnzahlCol(isSplitView)}>
                    <Form.Control name="amount-multiplier" accepter={RadioGroup} inline onChange={(v) => amountChange(v)}>
                        <ButtonGroup>
                            <Button
                                appearance="ghost"
                                disabled={1 === amount}
                                onClick={() => amountChange(1)}
                            >
                                1
                            </Button>

                            {1 < destination?.amount && (
                                <Button
                                    appearance="ghost"
                                    disabled={amount === destination.amount}
                                    onClick={() => amountChange(destination.amount)}
                                >
                                    {destination.amount}
                                </Button>
                            )}
                        </ButtonGroup>

                        <InputNumber
                            className="custom-input-number"
                            value={amount}
                            onChange={amountChange}
                            max={999999}
                            min={1}
                            style={{ width: "100px", marginLeft: "15px", marginRight: "15px" }}
                        />
                    </Form.Control>
                </Col>
            )}
            {/* need to change */}
            <Col {...getPriceCol(hideAnzahlCol, isSplitView)}>
                <Form.Control
                    style={{ width: "100%" }}
                    name={id}
                    accepter={CurrencyInput}
                    debounce={750}
                    value={price}
                    onChange={(price) => dispatch(setAssignmentPrice(id, price))}
                />
            </Col>

            <Col {...getPercentageCol(hideAnzahlCol, isSplitView)}>
                {!!percentage && percentageValueConverter.toView(percentage)}
            </Col>

            <Col {...getPercentageCol(hideAnzahlCol, isSplitView)}>
                {!!calculatedSum &&
                    `${currencyValueConverter.toView({
                        amount: price.amount / amount,
                        currency: price.currency,
                    })} × ${amount}`}
            </Col>

            <Col {...getPercentageCol(hideAnzahlCol, isSplitView)}>
                {calculatedSum?.amount && currencyValueConverter.toView(calculatedSum)}
            </Col>

            <Col {...getActionCol(isSplitView)}>
                <DeleteButton origin={origin} id={id} />
            </Col>
        </Row>
    );
}

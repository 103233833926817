import {inject, bindable, customElement, bindingMode} from 'aurelia-framework';
import * as _ from 'lodash';

@customElement('sio-condition-builder')
export class ConditionBuilder
{
    @bindable({defaultBindingMode: bindingMode.oneWay}) fields = [];

    @bindable submit;

    formConfiguration = {};

    submitInternal(object)
    {
        object = _.cloneDeep(object);
        let conditions = {};

        _.each(object, (element, key) => {

            if (_.isArray(element)) {
                let gt = element[0];
                let lt = element[1];

                if (gt || lt) {
                    let condition = {};

                    if (gt) {
                        condition['$gt'] = gt;
                    }
                    if (lt) {
                        condition['$lt'] = lt;
                    }

                    conditions[key] = condition;
                }

            } else if (element) {
                conditions[key] = {
                    $match: element
                };
            }

        });

        return this.submit({
            object: conditions
        });
    }

    fieldsChanged()
    {
        let formFields = [];

        for (let config of this.fields) {
            let element;

            if (config.type === 'string') {

                element = {
                    type: 'input',
                    label: config.label,
                    property: config.fieldName,
                    required: false
                };


                formFields.push(element);

            } else if (config.type === 'date') {

                element = {
                    type: 'collection',
                    label: config.label,
                    property: config.fieldName,
                    fields: [
                        {
                            type: 'date',
                            property: 0,
                        },
                        {
                            type: 'date',
                            property: 1
                        }
                    ],
                    template: `
<div class="row">
    
    <div class="col-xs-6">
        <compose view-model.bind="'./element/'+fields[0].type+'/'+fields[0].type+'-element'"
                 model.bind="{config: fields[0], value: formService.getValue(fields[0].fullProperty)}"
                 siochange.trigger="formService.change(fields[0], $event.detail)"></compose>
    </div>

    <div class="col-xs-6">
        <compose view-model.bind="'./element/'+fields[1].type+'/'+fields[1].type+'-element'"
                 model.bind="{config: fields[1], value: formService.getValue(fields[1].fullProperty)}"
                 siochange.trigger="formService.change(fields[1], $event.detail)"></compose>
    </div>

</div>`
                };

                formFields.push(element);
            }
        }

        this.formConfiguration = {
            fields: formFields
        };
    }
}

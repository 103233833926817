import {bindable, customElement, inject, LogManager} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {transformComponentDateToModelDate, transformModelDateToComponentDate} from "./transformer";
import moment from "moment-timezone";
import {EventAggregator} from "aurelia-event-aggregator";

const logger = LogManager.getLogger('DateInput');

//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@customElement('sio-date-input')
@inject(BindingSignaler, Element, EventAggregator)
export class DateInput {
    @bindable field;
    @bindable({defaultBindingMode: 2}) modelDate;

    @bindable format = 'YYYY-MM-DDTHH:mm:ssZ';
    @bindable options = {};
    @bindable withTime = true;
    @bindable readonly = false;
    // `date` and `time` are two internal component values,
    // both of these properties have string type and converted to modelDate on change.
    date = null;
    time = null;

    viewChange = false;

    constructor(signaler, element, ea) {
        this.signaler = signaler;
        this.element = element;
        this.ea = ea;
    }

    get pickerType() {
        return 'native';
    }

    bind() {
        // We need to wait till options are bound. This is why bind() here is mandatory.
        this._modelDateChanged();
    }

    attached()
    {
        this.subscription = this.ea.subscribe(
            'sio_form_value_changed', this.onFormValueChangedCallback.bind(this)
        );
    }

    detached()
    {
        this.subscription.dispose();
    }

    onFormValueChangedCallback(event)
    {
        // Verification that choice input belongs to form that fired event.

        if (event.form?.formService !== this.field?.formService) {
            return;
        }

        if (event.field.property !== this.options?.durationField) {
            return;
        }

        if (!this.options?.durationField || !this.options?.startField) {
            return;
        }

        let currentValue = this.field.formService.getValue();

        let startValue = currentValue?.[this.options.startField];

        if (startValue && currentValue[this.options.durationField]) {

            let newDuration = this.options.nightMode ? currentValue[this.options.durationField] : currentValue[this.options.durationField] - 1;

            currentValue[this.field.property] = moment.utc(startValue).add(newDuration, 'days').toISOString() ;

            this.field.formService.setValue(currentValue);
        }
    }

    modelDateChanged(newValue, oldValue) {

        //If viewChange the change was initiated by a view change, so we do NOT need to update
        //See https://stackoverflow.com/a/45155901
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this._modelDateChanged();

        this._calculationDuration();
    }

    _modelDateChanged() {
        logger.debug('Value changed - To', this.modelDate);

        const {date, time} = transformModelDateToComponentDate(this.modelDate, this.format, this.options);

        this.date = date;
        this.time = time;

        this.signaler.signal('sio-date-input-changed');
    }

    _calculationDuration()
    {
        if (this.field && this.options?.durationField && (this.options?.startField || this.options?.endField)) {

            let currentValue = this.field.formService.getValue();

            let durationValue = currentValue?.[this.options.durationField];

            if (durationValue != null && !this.options.nightMode) {
                durationValue = durationValue - 1;
            }

            if (this.options?.startField && durationValue) {

                currentValue[this.options.startField] = moment.utc(this.date).add(-1 * durationValue, 'days').toISOString();

                this.field.formService.setValue(currentValue);
            }

            if (this.options?.endField && durationValue) {

                currentValue[this.options.endField] = moment.utc(this.date).add(durationValue, 'days').toISOString();

                this.field.formService.setValue(currentValue);
            }
        }
    }

    updateModelDate(newValue) {
        const {date, time} = newValue;

        this.date = date;
        this.time = time;

        this.viewChange = true;
        this.modelDate = transformComponentDateToModelDate(date, time, this.format, this.options);

        //Needed otherwise binding might not be up-to-date
        setTimeout(() => {
            this.element.dispatchEvent(new CustomEvent('sio-change', {detail: {value: this.modelDate}}));

            this._calculationDuration();
        }, 0);
    }
}

export class AverageRating {
    _getAverageRating(survey) {
        if (survey.answers) {
            let count = 0;
            let rating = 0;

            _.forEach(survey.answers, answer => {
                if (answer.rating && 0 < answer.rating) {
                    rating += answer.rating;
                    count++;
                }
            });

            if (count > 0) {
                return rating / count;
            }
        }

        return null;
    }
}

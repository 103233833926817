import * as _ from "lodash";

/**
 * Used to calculate Dr and Cr sums in accounting postings table
 */
export class CurrencySumValueConverter {

    toView(items, property) {

        if (null === items || 0 === items.length) {
            return;
        }


        if (property) {
            items = items.filter(item => item[property]).map(item => item[property]);
        }

        let resultSums = {};

        _.forEach(items, item => {

            if (!resultSums[item.currency]) {
                resultSums[item.currency] = 0;
            }

            resultSums[item.currency] += item.amount;
        });

        let result = [];

        _.forEach(resultSums, (amount, currency) => {
            result.push({amount: amount, currency: currency})
        });

        return result;
    }
}

import {bindable, customElement, inject, LogManager, NewInstance} from "aurelia-framework";
import {debounce} from '../utilities/debounce';
import {Client} from "../api/client";
import {TableService} from "../table/service/table-service";

const logger = LogManager.getLogger('table');

@customElement('sio-flight-segment-display')
export class FlightSegmentDisplay {

    @bindable segments = [];



}

import { inject, bindable, customElement, bindingMode, LogManager } from 'aurelia-framework';
import {Client} from '../api/client';

const logger = LogManager.getLogger('history-display');

@customElement('sio-history-display')
@inject(Client)
export class HistoryDisplay
{
    @bindable object;

    @bindable diff;

    diffItems = [];

    bind()
    {
        console.log('diff', this.diff);
    }

    constructor(client)
    {
        this.client = client;
    }

    async bind()
    {
        this.columns = [];

        this.diffItems = this.diff;
    }

    _getObject(property, value) {
        let obj = {};
        obj[property] = value;
        return obj;
    }
}

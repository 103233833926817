import React, {useEffect, useState} from "react";
import {ChoiceLoader} from "../../choice/loader/choice-loader";
import {I18N} from "aurelia-i18n";
import getService from "../../utilities/get-service";

export const ReactChoiceDisplay = ({config, value}) => {

    const [choices, setChoices] = useState(undefined);

    useEffect(() => {
        getService(ChoiceLoader).getChoices(config, null, value).then(choices => setChoices(choices));
    }, [config, value]);

    if (!choices) {
        return <span className="fa fa-spinner fa-pulse fa-fw"/>;
    }

    const i18n = getService(I18N);
    const labeler = v => choices.find(c => v === c.value)?.label;
    const label = config.options?.translateChoiceLabel ? v => i18n.tr(labeler(v)) : v => labeler(v);

    return Array.isArray(value) ? value.map(item => label(item)).join(', ') : (label(value) ?? 'Ungültiger Wert');
}

export default ReactChoiceDisplay;

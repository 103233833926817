import {inject} from 'aurelia-dependency-injection';
import {ViewResources} from 'aurelia-templating';
import {Parser, createOverrideContext} from 'aurelia-binding';

@inject(Parser, ViewResources)
export class ExpressionEvaluator {

    constructor(parser, resources) {
        this.parser = parser;
        this.lookupFunctions = resources.lookupFunctions;
    }

    evaluate(expressionText, bindingContext, overrideContext) {
        const expression = this.parser.parse(expressionText);
        const scope = {
            bindingContext,
            overrideContext: overrideContext
        };
        return expression.evaluate(scope, this.lookupFunctions);
    }
}
import moment from 'moment-timezone';

/**
 * Format date values according to format.
 *
 * Format defaults to date + time in moment locale
 */
export class DateValueConverter {

    constructor()
    {
        this.defaults = {
            format: 'L LT'
        }
    }

    toView(value, format, timezone) {
        if (!value) {
            return null;
        }

        value = moment(value);

        if (timezone) {
            value = value.tz(timezone);
        } else if (format === 'L') {
            // We need to interpret date as UTC here because in database we just save dates without time as 0:00:00 and timezones with negative offset
            // display date then wrong.
            value = value.tz('UTC');
        }

        return value.format(format || this.defaults.format);
    }

    range(from, to) {
        from = moment(from);
        to = moment(to);

        if (from.year() === to.year()) {
            if (from.month() === to.month()) {
                return from.day() === to.day() ?
                    from.format("DD.MM.Y") :
                    from.format("DD.") + " – " + to.format("DD.MM.Y");
            }
            return from.format("DD.MM.") + " – " + to.format("DD.MM.Y");
        }

        return from.format("DD.MM.Y") + " – " + to.format("DD.MM.Y");
    }
}

import {createEntityAdapter, createSelector, createSlice, nanoid} from "@reduxjs/toolkit";

// const itemsAdapter = createEntityAdapter()
//
// function updatePrices(state, field, entityId) {
//     let count = state.ids.length, sum = state[field].amount;
//     const ids = itemsAdapter.getSelectors().selectIds(state)
//
//     for (const id of ids) {
//         if (id === entityId || state.entities[id]?.[field + "Overwritten"]) {
//             --count;
//             sum -= state.entities[id][field].amount;
//         }
//     }
//
//     let rest = sum, first = true;
//     const updates = [];
//
//     for (const id of ids) {
//         if (id === entityId || state.entities[id]?.[field + "Overwritten"]) {
//             continue;
//         }
//
//         const amount = Math.min(rest, first ? Math.ceil(sum / count) : Math.floor(sum / count));
//
//         updates.push({id: id, changes: {[field]: {amount, currency: state[field].currency}}})
//         rest -= amount;
//         first = false;
//     }
//
//     itemsAdapter.updateMany(state, updates);
//     state.needsUpdate = true;
// }
//
// function setAndUpdatePrices(entityId, state, field, amount, currency) {
//     if (entityId) {
//         itemsAdapter.updateOne(state, {
//             id: entityId,
//             changes: {
//                 [field]: {amount, currency},
//                 [field + "Overwritten"]: true
//             }
//         })
//     } else {
//         state[field] = {amount, currency};
//     }
//
//     updatePrices(state, field, entityId);
// }

const percentSplitAdapter = createEntityAdapter()

function calculatePercentSplits(state) {
    let percentageSum = .0, amountSum = 0

    Object.entries(state.percentSplit.entities).forEach(([id, {percentage}]) => {
        if (percentage < .0) {
            percentage = .0
        } else if (percentage > 1.0) {
            percentage = 1.0
        }
        const amount = Math.round(state.realMargin * percentage)
        percentSplitAdapter.updateOne(state.percentSplit, {id, changes: {percentage, amount}})

        percentageSum += percentage
        amountSum += amount
    })

    const percentageDiff = .01 * Math.round(100 - percentageSum * 100)

    if (percentageDiff) {
        Object.entries(state.percentSplit.entities).every(([id, {percentage}]) => {
            if (!(0.0 <= (percentage + percentageDiff) && (percentage + percentageDiff) <= 1.0)) {
                return true
            }

            percentSplitAdapter.updateOne(state.percentSplit, {
                id, changes: {
                    percentage: percentage + percentageDiff
                }
            })
            calculatePercentSplits(state)
            return false
        })
        return
    }

    let amountDiff = state.realMargin - amountSum

    if (amountDiff) {
        const delta = Math.ceil(amountDiff / state.percentSplit.ids.length)
        Object.entries(state.percentSplit.entities).every(([id, {amount}]) => {
            percentSplitAdapter.updateOne(state.percentSplit, {id, changes: {amount: amount + delta}})
            amountDiff -= delta
            return 0 !== amountDiff
        })
    }
}

const reportSlice = createSlice({
    name: "costs/report",
    initialState: {
        realMargin: 0,
        currency: "EUR",
        percentSplit: percentSplitAdapter.getInitialState(),
        canSave: false,
    },
    // initialState: itemsAdapter.getInitialState({
    //     purchasePrice: {amount: 0, currency: "EUR"},
    //     calculatedPurchasePrice: {amount: 0, currency: "EUR"},
    //     needsUpdate: false
    // }),
    reducers: {
        addPercentSplit(state) {
            percentSplitAdapter.addOne(state.percentSplit, {id: nanoid(), percentage: .0, amount: 0})
            state.canSave = false
        },

        removePercentSplit: {
            prepare: id => ({payload: {id}}),
            reducer(state, {payload: {id}}) {
                const diff = state.percentSplit.entities[id].percentage / (state.percentSplit.ids.length - 1)

                Object.entries(state.percentSplit.entities).forEach(([id, {percentage}]) =>
                    percentSplitAdapter.updateOne(state.percentSplit, {
                        id,
                        changes: {percentage: parseFloat((percentage + diff).toFixed(2))}
                    })
                )

                percentSplitAdapter.removeOne(state.percentSplit, id)
                calculatePercentSplits(state)
                state.canSave = Object.values(state.percentSplit.entities).every(({account}) => !!account)
            }
        },

        changePercentSplit: {
            prepare: (id, percentage) => ({payload: {id, percentage}}),
            reducer(state, {payload: {id, percentage}}) {
                const diff = (percentage - state.percentSplit.entities[id].percentage) / (state.percentSplit.ids.length - 1)

                percentSplitAdapter.updateMany(
                    state.percentSplit,
                    Object.entries(state.percentSplit.entities).map(([i, {percentage: p}]) => ({
                        id: i,
                        changes: {
                            percentage: parseFloat((i === id ? percentage : p - diff).toFixed(2))
                        }
                    }))
                )

                calculatePercentSplits(state)
            }
        },

        setPercentSplitAccount: {
            prepare: (id, account) => ({payload: {id, account}}),
            reducer(state, {payload: {id, account}}) {
                percentSplitAdapter.updateOne(state.percentSplit, {id, changes: {account}})
                state.canSave = Object.values(state.percentSplit.entities).every(({account}) => !!account)
            },
        },

        resetPercentSplits: {
            prepare: realMargin => ({payload: realMargin}),
            reducer(state, {payload: {amount: realMargin, currency}}) {
                state.realMargin = realMargin
                state.currency = currency
                percentSplitAdapter.setAll(
                    state.percentSplit,
                    [
                        {id: nanoid(), percentage: .5},
                        {id: nanoid(), percentage: .5},
                    ]
                )
                calculatePercentSplits(state)
            }
        }

        // setPurchasePrice: {
        //     prepare: ({amount, currency}, id) => ({payload: {amount, currency, id}}),
        //     reducer(state, {payload: {amount, currency, id}}) {
        //         setAndUpdatePrices(id, state, "purchasePrice", amount, currency);
        //     }
        // },
        // togglePurchasePriceOverwritten: {
        //     prepare: id => ({payload: {id}}),
        //     reducer(state, {payload: {id}}) {
        //         itemsAdapter.updateOne(state, {
        //             id,
        //             changes: {
        //                 purchasePriceOverwritten: !state.entities[id]?.purchasePriceOverwritten
        //             }
        //         });
        //         updatePrices(state, "purchasePrice");
        //     }
        // },
        // toggleCalculatedPurchasePriceOverwritten: {
        //     prepare: id => ({payload: {id}}),
        //     reducer(state, {payload: {id}}) {
        //         itemsAdapter.updateOne(state, {
        //             id,
        //             changes: {
        //                 calculatedPurchasePriceOverwritten: !state.entities[id]?.calculatedPurchasePriceOverwritten
        //             }
        //         });
        //         updatePrices(state, "calculatedPurchasePrice");
        //     }
        // },
        // setCalculatedPurchasePrice: {
        //     prepare: ({amount, currency}, id) => ({payload: {amount, currency, id}}),
        //     reducer(state, {payload: {amount, currency, id}}) {
        //         setAndUpdatePrices(id, state, "calculatedPurchasePrice", amount, currency);
        //     }
        // },
        // setItems: {
        //     prepare: items => ({
        //         payload: items.map(
        //             ({
        //                  id,
        //                  bookingStatus,
        //                  participants,
        //                  order: {
        //                      id: orderId,
        //                      orderNumber,
        //                      invoiceNumber
        //                  }
        //              }) => ({
        //                 id,
        //                 orderId,
        //                 bookingStatus,
        //                 participants,
        //                 orderNumber,
        //                 invoiceNumber
        //             }))
        //     }),
        //     reducer(state, {payload: items}) {
        //         itemsAdapter.setAll(state, items)
        //     }
        // },
        // updated(state) {
        //     state.needsUpdate = false;
        // }
    },
});

const report = reportSlice.reducer;
export default report;

export const {
    addPercentSplit,
    removePercentSplit,
    changePercentSplit,
    setPercentSplitAccount,
    resetPercentSplits,
    // setItems,
    // setCalculatedPurchasePrice,
    // setPurchasePrice,
    // toggleCalculatedPurchasePriceOverwritten,
    // togglePurchasePriceOverwritten,
    // updated,
} = reportSlice.actions;

const selectReport = createSelector(state => state.costs, ({report}) => report)
// export const selectCalculatedPurchasePrice = createSelector(selectReport, ({calculatedPurchasePrice}) => calculatedPurchasePrice);
// export const selectPurchasePrice = createSelector(selectReport, ({purchasePrice}) => purchasePrice);
// export const selectNeedsUpdate = createSelector(selectReport, ({needsUpdate}) => needsUpdate);
//
// export const {
//     selectAll,
//     selectById
// } = itemsAdapter.getSelectors(selectReport);


export const {
    selectIds: selectPercentSplitIds,
    selectById: selectPercentSplit,
    selectAll: selectPercentSplits,
} = percentSplitAdapter.getSelectors(state => selectReport(state).percentSplit)

export const selectCurrency = createSelector(selectReport, ({currency}) => currency)
export const selectCanSave = createSelector(selectReport, ({canSave}) => canSave)
export const selectPercentSplitSave = createSelector(
    [selectCurrency, selectPercentSplits],
    (currency, splits) => splits.map(({amount, account}) => ({account, amount: {amount, currency}}))
)

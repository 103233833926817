import React from "react";
import {bindable, bindingMode, customElement, inject, noView} from "aurelia-framework";
import {render, unmountComponentAtNode} from "react-dom";
import {Provider} from "react-redux";
import store from "../store/store";
import {MarginReport} from "./margin-report/margin-report";
import {UserClient} from "../api/user-client";

@noView
@inject(Element, UserClient)
@customElement("margin-report")
export class CostReportAssignments {
    @bindable({defaultBindingMode: bindingMode.oneTime}) reference;
    @bindable({defaultBindingMode: bindingMode.oneTime}) report;

    constructor(container) {
        this.container = container;
    }

    attached() {
        render(
            <Provider store={store}>
                <MarginReport reference={this.reference} report={this.report}/>
            </Provider>,
            this.container
        );
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}

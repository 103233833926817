import StatsLabel from "../../statistics/time-aggregation/stats-label";
import {Button, Table} from "rsuite";
import {useContext} from "react";
import AureliaContext from "../../utilities/aurelia-context";
import {useStyles} from "../origin/constants";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";
import {useDeleteCostSourceMutation, useSetCostSourcePriceMutation} from "../store/costs-api";
import confirm from "../../dialog/confirm";
import {CurrencyInput} from "../../form/rsuite-form";
import Panel from "../../view-block/panel/react-panel";
import {EditCell} from "./edit-cell";
import {Plus} from "@rsuite/icons";

const {Column, HeaderCell, Cell} = Table
const actionConfig = {
    type: "workflow",
    formId: "costs/add-cost-source",
    workflowId: "costs/add-cost-source",
    label: <Plus width=".667em" height=".667em"/>,
    confirmTitle: "costs.add-cost-source",
    buttonClass: "btn btn-success"
}

/**
 *
 * @param identifier
 * @param {CostSource[]} costSources
 * @param {object[]} labels
 * @param {?Money} calculatedCosts
 * @param {?Money} realCosts
 * @param {function} refetch
 * @param {string} currency
 * @param {boolean} locked
 * @param {?Money} lockedRealCosts
 */
export function CostSources(
    {
        identifier,
        costSources,
        labels,
        calculatedCosts,
        realCosts,
        refetch,
        currency,
        locked,
        lockedRealCosts
    }
) {
    const {i18n, currencyValueConverter} = useContext(AureliaContext)
    const data = Object.entries(costSources).map(([key, {calculatedCosts, realCosts, lastLockedRealCosts, dirty}]) => ({
        key,
        label: <StatsLabel label={labels[key]}/>,
        readonly: locked || !!labels[key]?.readonly,
        calculatedCosts: (
            <EditCell
                value={calculatedCosts ?? (!!labels[key]?.readonly ? null : {amount: 0, currency})}
                renderer={amount => !amount?.currency ? "" : currencyValueConverter.toView(amount)}
                editor={CurrencyInput}
                mutation={useSetCostSourcePriceMutation}
                transformSave={calculatedCosts => ({identifier: key, calculatedCosts})}
                readonly={locked}
                stack={{justifyContent: "flex-end"}}
            />
        ),
        realCosts: (
            <EditCell
                value={realCosts ?? (!!labels[key]?.readonly ? null : {amount: 0, currency})}
                renderer={amount => !amount?.currency ? "" : (
                    dirty ? <strong>{currencyValueConverter.toView(amount)}</strong> :
                        currencyValueConverter.toView(amount)
                )}
                editor={CurrencyInput}
                mutation={useSetCostSourcePriceMutation}
                transformSave={realCosts => ({identifier: key, realCosts})}
                readonly={locked || !!labels[key]?.readonly}
                stack={{justifyContent: "flex-end"}}
            />
        ),
        booked: !lastLockedRealCosts?.currency ? "" : currencyValueConverter.toView(lastLockedRealCosts),
        dirty: dirty && !!lastLockedRealCosts?.currency,
    })).concat([{
        key: "sum",
        label: <strong>Summe:</strong>,
        calculatedCosts: <strong>{currencyValueConverter.toView(calculatedCosts)}</strong>,
        realCosts: <strong>{currencyValueConverter.toView(realCosts)}</strong>,
        readonly: true,
        booked: !lockedRealCosts?.currency ? "" : currencyValueConverter.toView(lockedRealCosts)
    }])

    return (
        <Panel
            heading="costs.costs"
            reference={{data: {formData: {identifier}}}}
            actionConfig={locked ? null : actionConfig}
            onSubmitted={() => refetch()}
        >
            <Table isTree autoHeight data={data} rowKey="key" wordWrap="break-word">
                <Column flexGrow={1}>
                    <HeaderCell>{i18n.tr("sio.field.creditor")}</HeaderCell>
                    <Cell dataKey="label"/>
                </Column>
                <Column flexGrow={1} align="right">
                    <HeaderCell>{i18n.tr("costs.calculatedCosts")}</HeaderCell>
                    <Cell dataKey="calculatedCosts"/>
                </Column>
                <Column flexGrow={1} align="right">
                    <HeaderCell>{i18n.tr("costs.realCosts")}</HeaderCell>
                    <Cell dataKey="realCosts"/>
                </Column>
                <Column flexGrow={1} align="right">
                    <HeaderCell>{i18n.tr("costs.fixed")}</HeaderCell>
                    <Cell dataKey="booked"/>
                </Column>
                <Column width={30}>
                    <HeaderCell/>
                    <Cell>
                        {({key, readonly, dirty}) => (
                            <>
                                {dirty && <span style={{position: "relative", left: "-8px"}}>❗</span>}
                                {(!readonly) && <DeleteButton identifier={key}/>}
                            </>
                        )}
                    </Cell>
                </Column>
            </Table>
        </Panel>
    );
}

function DeleteButton({identifier}) {
    const {i18n} = useContext(AureliaContext)
    const {danger, btn} = useStyles()
    const [doDelete, {isLoading}] = useDeleteCostSourceMutation()

    const onClick = () => confirm(i18n.tr("costs.delete-cost-source"), i18n.tr("sio.are_you_sure"))
        .then(() => doDelete(identifier));

    return (
        <Button loading={isLoading}
                onClick={() => onClick()}
                size="xs"
                className={danger + " " + btn}
                appearance="link">

            <SioIcon icon="fa fa-trash"/>
        </Button>
    );
}

import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";

@inject(DialogController)
export class Preview {

    constructor(dialogController) {
        this.dialogController = dialogController;
    }

    async activate(context) {
        this.title = context.title;
        this.message = context.message;
    }
}

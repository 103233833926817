import {bindable, computedFrom, customElement} from 'aurelia-framework';
import * as _ from "lodash";

@customElement('sio-form-label')
export class FormLabel
{
    @bindable field;

    @computedFrom('field')
    get label()
    {
        if (!this.field.label) {
            return "";
        } else {
            return _.isObject(this.field.label) ? this.field.label.value : this.field.label;
        }
    }

    @computedFrom('field')
    get help()
    {
        if (this.field.help && this.field.help != '') {
            return this.field.help;
        } else if (this.field.options && this.field.options.help) {
            return this.field.options.help;
        } else {
            return false;
        }
    }

    @computedFrom('field')
    get required()
    {
        if (!this.field.label) {
            return false;
        } else {
            return this.field.required;
        }
    }

    @computedFrom('field')
    get hidden()
    {
        if (!this.field.label) {
            return false;
        } else {
            return _.isObject(this.field.label) ? this.field.label.hidden : false;
        }
    }
}

import {inject, LogManager} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {EventAggregator} from "aurelia-event-aggregator";
import {Client} from "../../api/client";
import {UniversalEntitySelect} from "../../dialog/universal-entity-select";
import * as _ from "lodash";

import "./frontend-menu-configuration.less";

const logger = LogManager.getLogger("FrontendMenuConfiguration");

@inject(
    Client,
    DialogService,
    EventAggregator
)
export class FrontendMenuConfiguration {
    menu = [];

    constructor(
        client,
        dialog,
        ea
    ) {
        this.client = client;
        this.dialog = dialog;
        this.ea = ea;
    }

    async attached()
    {
        // Fetch menu configuration from server

        await this.fetchMenu();

        logger.debug("Loaded menu items", this.menu);
    }

    async fetchMenu()
    {
        this.menu = await this.client.get('frontend/menu');
        this.menu = _.map(
            _.filter(
                _.values(this.menu),
                item => {
                    return item.name !== 'Reiseziele' &&
                           item.name !== 'Reisen' &&
                           item.name !== 'Reisearten';
                }
            ),
            item => {
                item.subitems = _.filter(item.subitems, item => {
                    return !!item.contentPage;
                });

                return item;
            }
        );
    }

    async addItems(parent)
    {
        const response = await this.dialog.open({viewModel: UniversalEntitySelect, model: {
            selectModelId: 'cms/content-page',
            allowDuplicates: true,
            multiSelect: true
        }}).whenClosed();

        if (response.wasCancelled || !response.output || response.output.length === 0) {
            return;
        }

        logger.debug('Selected items', response.output);

        await this.client.post('frontend/menu/items', {
            parent: parent.id,
            pages: _.map(response.output, item => item.id)
        });
        await this.fetchMenu();
    }

    async save(newMenu)
    {
        // Save menu configuration onto server

        this.client.put('frontend/menu', newMenu);
    }
}

import {inject} from 'aurelia-framework';
import * as _ from 'lodash';
import {Client} from '../../api/client';

@inject(Client)
export class ConfigurationLoader {

    constructor(client) {
        this.client = client;
    }

    get(config, data) {

        if ("string" === typeof config) {

            return this.client
                .post(
                    'form/config/' + config,
                    'string' === typeof data ? {id: data} : 'object' === typeof data ? data : undefined
                )
                .then(({data}) => _.cloneDeep(data));

        } else if ("object" === typeof config) {

            return Promise.resolve(_.cloneDeep(config));

        } else {
            return Promise.reject(new Error('Invalid configuration'));
        }
    }
}

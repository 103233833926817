import {Button, Col, Divider, FlexboxGrid, Input, Loader} from "rsuite";
import {useChangeAccountMutation, useGetMarginReportQuery, useMarginReportLabelMutation} from "../store/costs-api";
import {CostSources} from "./cost-source";
import {IncomeSources} from "./income-source";
import {Margin} from "./margin";
import {MarginBookings} from "./margin-booking";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";
import React, {useContext, useState} from "react";
import AureliaContext from "../../utilities/aurelia-context";
import {ActionButton} from "../../action/react/action-button";
import StatsLabel from "../../statistics/time-aggregation/stats-label";
import {EditCell} from "./edit-cell";
import {AccountSelect} from "../../accounting/entry/form-elements";
import {doDownload} from "../../utilities/download";

const unlockConfig = {
    type: "workflow",
    buttonClass: "btn btn-danger",
    label: "costs.unlock-margin-report",
    confirmTitle: "costs.unlock-margin-report",
    formId: "costs/unlock-margin-report",
    workflowId: "costs/unlock-margin-report",
}

export function MarginReport({reference, report}) {
    const {i18n} = useContext(AureliaContext)
    const {isLoading, isFetching, data, refetch} = useGetMarginReportQuery({reference, report});

    if (isLoading || !data) {
        return <Loader/>;
    }

    const {
        incomeSourceCategories,
        costSources,
        marginBookings,
        identifier,
        reportId,
        report: {locked, income, calculatedCosts, realCosts, unassignedMargin, ledgerAccount: {organizationId}},
        lockedMarginBookings,
        meta: {labels, canBook, canUnlock}
    } = data;

    const calculatedMargin = !income?.amount || !calculatedCosts?.amount ? null : {
        amount: income.amount - calculatedCosts.amount,
        currency: income.currency
    }

    const realMargin = !income?.amount || !realCosts?.amount ? null : {
        amount: income.amount - realCosts.amount,
        currency: income.currency
    }

    const currency = realCosts?.currency ?? income?.currency;

    return (
        <FlexboxGrid justify="space-between">
            {locked && (
                <FlexboxGrid.Item colspan={24}>
                    <div className="alert alert-info" style={{marginBottom: "1em"}}>
                        {canUnlock && (
                            <div className="float-right" style={{marginTop: "-6px"}}>
                                <ActionButton
                                    size="xs"
                                    config={unlockConfig}
                                    context={{data: {formData: {identifier}}}}
                                    onSubmitted={() => refetch()}
                                />
                            </div>
                        )}

                        <SioIcon icon="fa fa-lock" style={{marginRight: ".5em"}}/>
                        {i18n.tr("costs.margin-report-locked")}
                    </div>
                </FlexboxGrid.Item>
            )}

            <FlexboxGrid.Item as={Col} colspan={24}>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div style={{display: "flex", gap: "8px", alignItems: "center"}}>
                            <EditCell
                                value={labels.account}
                                editor={AccountSelect}
                                mutation={useChangeAccountMutation}
                                transformSave={account => ({identifier, account})}
                                renderer={value => <StatsLabel label={{...labels.account, ...value}}/>}
                                buttonsRight
                                organization={organizationId}
                                readonly={locked}
                            />

                            <Divider vertical/>

                            <div style={{flexGrow: 1}}>
                                <EditCell
                                    value={labels.label}
                                    editor={Input}
                                    mutation={useMarginReportLabelMutation}
                                    transformSave={label => ({id: report?.id ?? reportId, label})}
                                    renderer={label => label}
                                    readonly={!report?.id && !reportId}
                                    buttonsRight
                                />
                            </div>

                            <ExcelDownload identifier={identifier} label={labels.label}/>
                        </div>
                    </div>
                </div>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={24} lg={12}>
                <IncomeSources
                    categories={incomeSourceCategories}
                    income={income}
                    labels={labels}
                />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item as={Col} colspan={24} lg={12}>
                <CostSources
                    identifier={identifier}
                    costSources={costSources}
                    calculatedCosts={calculatedCosts}
                    realCosts={realCosts}
                    reference={reference}
                    labels={labels}
                    refetch={refetch}
                    currency={currency}
                    locked={locked}
                />
            </FlexboxGrid.Item>

            {(calculatedCosts?.amount || realCosts?.amount) && (
                <FlexboxGrid.Item as={Col} colspan={24} lg={12}>
                    <Margin
                        report={report}
                        calculatedCosts={calculatedCosts}
                        calculatedMargin={calculatedMargin}
                        income={income}
                        realCosts={realCosts}
                        realMargin={realMargin}
                    />
                </FlexboxGrid.Item>
            )}

            {(lockedMarginBookings || realCosts?.amount) && (
                <FlexboxGrid.Item as={Col} colspan={24} lg={12}>
                    <MarginBookings
                        identifier={identifier}
                        reference={reference}
                        unassignedMargin={unassignedMargin}
                        refetch={refetch}
                        realMargin={realMargin}
                        labels={labels}
                        marginBookings={marginBookings}
                        organizationId={organizationId}
                        locked={locked}
                        readonly={lockedMarginBookings}
                        canBook={canBook}
                    />
                </FlexboxGrid.Item>
            )}

            {isFetching && <Loader backdrop vertical/>}
        </FlexboxGrid>
    )
}

function ExcelDownload({identifier, label}) {
    const [loading, setLoading] = useState(false)

    return (
        <Button
            size="sm"
            loading={loading}
            onClick={() => doDownload("costs/margin-report-excel/" + identifier, null, setLoading, label + ".xlsx")}
        >
            <span className="fa fa-file-export"/>
        </Button>
    )

}

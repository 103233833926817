import {inject} from 'aurelia-framework';
import {Client} from '../../api/client';

@inject(Client)
export class SubmitHandler
{
    constructor(client)
    {
        this.client = client;
    }

    apiSubmit(context)
    {
        let object = context.object;
        let config = context.config;

        if (context.identifier) {
            if (_.isArray(context.identifier) || true === config.bulk) {
                context.entities = context.identifier;
                return this.bulkApiSubmit(context);
            }
            return this.client.put(config.moduleId + '/' + config.id + '/' + context.identifier, object);
        } else {
            return this.client.post(config.moduleId + '/' + config.id, object);
        }
    }

    bulkApiSubmit(context)
    {
        const config = context.config;
        const data = context.hasOwnProperty('object') ?
            {ids: context.entities, object: context.object} :
            context.entities;

        return this.client.post('bulk-form/' + config.moduleId + '/' + config.id, data);
    }
}

import {useContext} from "react"
import {IconButton} from "rsuite"
import {useRealCostsDeleteAssignmentMutation} from "../store/costs-api"
import {confirm} from "../../dialog/confirm"
import {useStyles} from "./constants"
import SioIcon from "../../icon/rsuite-icon-font/SioIcon"
import AureliaContext from "../../utilities/aurelia-context";

export default function DeleteButton({origin, id}) {
    const {i18n} = useContext(AureliaContext)
    const [doDelete, {isFetching}] = useRealCostsDeleteAssignmentMutation()
    const {danger} = useStyles()

    function onClick() {
        confirm(i18n.tr("sio.delete"), i18n.tr("sio.are_you_sure"))
            .then(() => doDelete({origin, id}))
    }

    return (
        <IconButton
            loading={isFetching}
            className={danger}
            size="xs"
            icon={<SioIcon icon="fa fa-trash"/>}
            onClick={() => onClick()}
        />
    )
}

import {inject, Container} from "aurelia-framework";
import {DialogController, DialogService} from "aurelia-dialog";

import "./confirm.less";

@inject(DialogController)
export class Confirm {

    dialogController;
    keypressCallback;

    constructor(dialogController) {
        this.dialogController = dialogController;
        this.keypressCallback = this.keypress.bind(this);
    }

    async activate(context) {
        this.title = context.title;
        this.message = context.message;
        this.okMessage = context.okMessage;
        this.cancelMessage = context.cancelMessage;
        this.context = {params: context.params};

        // wait for rending gets finished before adding event listener
        window.setTimeout(() => {
            window.addEventListener('keypress', this.keypressCallback);
        }, 250);
    }

    deactivate() {
        window.removeEventListener('keypress', this.keypressCallback);
    }

    keypress(event) {
        if (13 === event.keyCode) {
            this.dialogController.ok();
        } else if (27 === event.keyCode) {
            this.dialogController.cancel();
        } else {
            return true;
        }
    }
}

/** @type {(title: string, message: string) => Promise} */
export const confirm = (title, message) => Container.instance.get(DialogService)
    .open({
        viewModel: Confirm,
        model: {
            message,
            title,
            okMessage: 'sio.execute',
            cancelMessage: 'sio.cancel'
        }
    })
    .whenClosed(({wasCancelled}) => wasCancelled ? Promise.reject() : Promise.resolve());

export default confirm;

import * as d3 from "d3-format";
import {currencies, locales} from "./model/currencies.model";
import {I18N} from 'aurelia-i18n';
import {inject} from 'aurelia-framework';

/**
 * @type {{
 *     toView: (obj: ?Money, mode?: "finance"|"financeInverted"|"number") => string
 * }}
 */
@inject(I18N)
export class CurrencyValueConverter {

    constructor(i18n) {
        this.i18n = i18n;
    }

    toView(obj, mode, itemAmount) {
        if (!obj) {
            return '';
        }

        if (obj.prices) {
            let returnValue = [];

            for (let price of obj.prices) {
                returnValue.push(((price.amount > 1 && itemAmount !== price.amount) && obj.prices.length > 1 ? price.amount + 'x ' : '') + this.toView(price.price, mode));
            }

            return returnValue.join('<br>');
        }

        if (!obj.currency || null === obj.amount || undefined === obj.amount) {
            throw new Error("Currency and amount must be set to format money");
        }

        if (null === currencies[obj.currency]) {
            throw new Error('Currency data not set.');
        }

        const currencyLocale = currencies[obj.currency] ?? null;
        let value = obj.amount / Math.pow(10, currencyLocale.decimal_digits);
        const negative = value < 0;

        if (('finance' === mode || 'financeInverted' === mode) && negative) {
            value *= -1;
        }

        const locale = locales[this.i18n.getLocale().substring(0, 2)];

        if (null !== currencyLocale) {
            for (let i = 0; i < locale.currency.length; i++) {
                const currencyLocaleD3 = locale.currency[i];
                if (null !== currencyLocaleD3 && '' !== currencyLocaleD3) {
                    locale.currency[i] = currencyLocale.symbol_native;
                }
            }
        }

        const result = d3.formatLocale(locale)
            .format(`${"number" !== mode ? "$" : ""},.${currencyLocale.decimal_digits}f`)(value);

        return 'finance' === mode ? result + '\u202F' + (negative ? 'S' : 'H') :
            //Note: In sage export (+ datev export) we used positive amounts for S as it's the "normal" case for bookings
            //In most cases you do book S instead of H (rule: Soll an Haben)
            "financeInverted" === mode ? result + '\u202F' + (negative ? 'H' : 'S') :
                result;
    }
}

// import {DialogController} from 'aurelia-dialog';
import {inject, customElement, bindable} from 'aurelia-framework';
import {Client} from "../api/client";
import {FlashService} from "../flash/flash-service";
import {EventAggregator} from 'aurelia-event-aggregator';

@customElement("sio-cost-table")
@inject( Client, FlashService, EventAggregator)
export class CostsTable {

    // dialogController;
    loading;
    items = [];
    ea;
    destination = false;
    @bindable item;
    @bindable columns;
    @bindable isStockOrder=false;

    constructor( client, flash, ea, item) {
        // this.dialogController = dialogController;
        this.client = client;
        this.flash = flash;
        this.ea = ea;

        this.reloadItemWorkflow = this.reloadItem.bind(this);

        this.item = item;
    }

    async attached() {
        // console.log("table", this.calculatedCostsEntries , this.item);
        this.loading = true
        this.orderItem = this.item;
        if (this.destination) {
            const {items, sum} = (await this.client.post("costs/query/real-costs/destination", {destination})).data;

            this.items = items
            this.sum = sum
            this.singleOrigin = false

        } else if (this.item?.calculatedCostsEntries?.length) {
            this.calculatedCostsEntries = this.item.calculatedCostsEntries;
            await this.updateItem();
        }

        this.items.forEach((item) => {
            item.actions = item.actions.filter((action) => action.type != "view");
        })

        this.loading = false
    }

    async updateItem() {
        this.loading = true;

        //Need to reload item because it might not be up to date after changes
        let calculatedCostsEntries = [];
        const url = this.isStockOrder ? "inventory-management/stock-order-item/" : "order/order-item/"
        if(Array.isArray(this.orderItem.id)) {
            for (const orderItemId of this.orderItem.id) {
                const orderItem = await this.client.get(url + orderItemId);
                if (orderItem?.calculatedCostsEntries) {
                  calculatedCostsEntries.push(...orderItem.calculatedCostsEntries);
                }
              }
        } else {
            const orderItem = (await this.client.get(url + this.orderItem.id));
            calculatedCostsEntries = orderItem?.calculatedCostsEntries ?? [];
        }

        const origins = calculatedCostsEntries.map(({origin}) => origin)

        this.singleOrigin = 1 === new Set(origins.map((obj) => obj?.id)).size
        this.reference = -1 !== calculatedCostsEntries.findIndex(({reference}) => !!reference)
        this.items = calculatedCostsEntries

        if (this.singleOrigin) {
            this.origin = origins[0]
        }

        this.loading = false;
    }

    getReturnContext(item) {
        return {
            formData: item.formData
        };
    }

    reloadItem()
    {
        console.log('UPDATE ITEM');
        this.updateItem().then(() => {});
        this.ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});
    }
}
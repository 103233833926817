import {bindable, customElement} from 'aurelia-framework';

@customElement('sio-html')

export class HtmlField {

    @bindable config;

    bind() {
        this.regex = this.config?.options?.regex;
        if (this.regex) {
            window.addEventListener("message", this.onMessage.bind(this), false);
        }
    }

    onMessage(event) {
        const str = JSON.stringify(event.data);
        if (new RegExp(this.regex).test(str)) {
            this.config?.setValue(str);
        }
    }

    deactivate() {
        window.removeEventListener("message", this.onMessage);
    }
}

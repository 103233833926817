import ReactDOM from "react-dom";
import {bindable, customElement, inject, noView} from 'aurelia-framework';
import {EventAggregator} from "aurelia-event-aggregator";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../flash/flash-service";
import {CurrencyValueConverter} from "../currency/currency-value-converter";
import {DateValueConverter, PercentageValueConverter} from "../converters";
import {ViewActionHandler} from "../action/handlers/view.action-handler";
import {UrlUtils} from "../utilities/url-utils";
import {Client} from "../api/client";
import React from "react";
import NotesReferenceDisplay from "./notes-reference-display";


@inject(
    Element,
    EventAggregator,
    DialogService,
    I18N,
    FlashService,
    CurrencyValueConverter,
    PercentageValueConverter,
    DateValueConverter,
    ViewActionHandler,
    UrlUtils,
    Client
)

@customElement('sio-journey-order-notes')
@noView()

export class SioJourneyOrderNotes {

    @bindable reference;

    constructor(
        element,
        ea,
        dialog,
        i18n,
        flash,
        currencyConverter,
        percentageConverter,
        dateConverter,
        viewActionHandler,
        urlUtils
    ) {
        this.element = element;
        this.ea = ea;
        this.dialog = dialog;
        this.i18n = i18n;
        this.flash = flash;
        this.currencyConverter = currencyConverter;
        this.percentageConverter = percentageConverter;
        this.dateConverter = dateConverter;
        this.viewActionHandler = viewActionHandler;
        this.urlUtils = urlUtils;
    }

    detached() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    async bind() {
        this.render();
    }

    render() {
        this.reactComponent = ReactDOM.render(
            <>
                <NotesReferenceDisplay reference={this.reference}/>
            </>,
            this.element
        );
    }

}

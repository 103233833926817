import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Form, Radio, RadioGroup} from "rsuite"
import {selectCurrencies, selectCurrency, setCurrency} from "../store/assignment-slice"

export default function CurrencySelect() {
    const dispatch = useDispatch()
    const currencies = useSelector(selectCurrencies)
    const currency = useSelector(selectCurrency)

    if (typeof currencies === 'undefined' || 2 > currencies.length) {
        return null
    }

    return (
        <Form.Group>
            <Form.ControlLabel>Währung:</Form.ControlLabel>

            <Form.Control
                name="currency"
                accepter={RadioGroup}
                inline
                value={currency}
                onChange={c => dispatch(setCurrency(c))}
            >
                {currencies.map(currency => (
                    <Radio key={currency} value={currency}>{currency}</Radio>
                ))}
            </Form.Control>
        </Form.Group>
    )
}

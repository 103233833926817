import { inject, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import moment from 'moment';
import numeral from 'numeral';

const logger = LogManager.getLogger('i18n');

@inject(I18N)
export class LocaleService {
    contentLocales;
    contentLocale;

    constructor(i18n) {
        this.i18n = i18n;
    }

    setLocale(user) {
        if (user?.settings?.userSettings?.locale) {

            const dateFormatMap = {
                'DD.MM.YYYY H:i:s': {
                    LT: 'H:mm',
                    LTS: 'H:mm:ss',
                    L: 'DD.MM.YYYY',
                    LL: 'D MMMM YYYY',
                    LLL: 'D MMMM YYYY HH:mm',
                    LLLL: 'dddd D MMMM YYYY HH:mm'
                },
                'MM/DD/YYYY H:i P': {
                    LT: 'H:mm',
                    LTS: 'H:mm aa',
                    L: 'M/D/YYYY',
                    LL: 'D MMMM YYYY',
                    LLL: 'D MMMM YYYY HH:mm',
                    LLLL: 'dddd D MMMM YYYY HH:mm'
                }
            };

            const { userSettings } = user?.settings || {};
            const locale = userSettings?.locale;
            const dateFormat = userSettings?.dateFormat;

            if (this.i18n.getLocale() !== locale) {
                logger.debug('Set locale from settings', locale);

                this.i18n.setLocale(locale);
            }

            switch (locale) {
                case 'de':
                    require('moment/locale/de');
                    require('numeral/locales/de');
                    moment.locale('de');
                    numeral.locale('de');
                    break;

                case 'de_AT':
                    require('moment/locale/de-at');
                    require('numeral/locales/de');
                    moment.locale('de-at');
                    numeral.locale('de');
                    break;

                case 'de_CH':
                    require('moment/locale/de-ch');
                    require('numeral/locales/de-ch');
                    moment.locale('de-ch');
                    numeral.locale('de-ch');
                    break;
            }

            /** Update date format based on settings selected by user*/
           if(dateFormat) {
                const formatOptions = dateFormatMap[dateFormat];
                moment.updateLocale(moment.locale, { longDateFormat: formatOptions } );
            }

        } else {

            //TODO Upload determined browser locale to user settings?
            logger.debug('No locale setting found');
        }


        this.contentLocales = user.instance?.settings?.instanceGeneral?.contentLocales;
        this.contentLocale = this.contentLocales?.[0] || 'de';
    }

    setContentLocale(contentLocale) {
        this.contentLocale = contentLocale;
    }
}

import {bindable, customElement} from 'aurelia-framework';
import moment from 'moment-timezone';

@customElement('due-display')
export class DueDisplay
{

    @bindable value;
    @bindable item;

    getClass()
    {
        if (!this.value) {
            return '';
        }

        let now = moment();

        let diff = moment(this.value).diff(now);

        console.debug('doff', diff);

        if (diff < 0) {
            return 'text-danger';
        } else if (diff < 86400 * 3 * 1000) {
            return 'text-warning';
        }

        return '';
    }
}
